<template>
  <div class="modal-mask" @keydown="key">
    <div class="modal-wrapper" @click="$emit('close')">
      <div class="row">

        <div class="col-12 text-center">
          <img v-if="image" :src="'/images/references/' + image.source" class="img-fluid img-thumbnail" v-on:click.stop="next" />
        </div>

        <div class="text-center btn-nav" id="btn-last" @click.stop="last()">
          <i class="fas fa-angle-left"></i>
        </div>

        <div class="text-center btn-nav" id="btn-next" @click.stop="next()">
          <i class="fas fa-angle-right"></i>
        </div>

        <div class="text-right btn-nav" id="btn-close" @click="$emit('close')">
          <i class="fas fa-times"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Image } from '@/model/Image';

@Options({
  emits: ['close'],
  props: {
    images: Array,
    current: Number
  }
})
export default class Caroussel extends Vue {

  images!: Image[];
  current?: number;
  index = 0;

  get image() {
    let image: Image | null = null;

    // this.index = 0;

    if (this.index >= 0) {
      image = this.images[this.index];
    }

    return image instanceof Image ? image : null;
  }

  created() {
    this.index = this.images.findIndex((image: Image) => {
      return image.id === this.current;
    });

    document.addEventListener('keydown', this.keydown);
  }

  keydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
        this.last();
        break;
      case 'ArrowRight':
        this.next();
        break;
      case 'Escape':
        this.$emit('close');
        break;
    }
  }

  last() {
    let index: number = this.images.findIndex((image: Image) => {
      return this.image instanceof Image && image.id === this.image.id;
    }) - 1;

    if (index === -1) {
      index = this.images.length - 1;
    }

    this.index = index;
  }

  next() {
    let index: number = this.images.findIndex((image: Image) => {
      return this.image instanceof Image && image.id === this.image.id;
    }) + 1;

    if (index === this.images.length) {
      index = 0;
    }

    this.index = index;
  }

}

</script>

<style scoped lang="scss">
i {
  font-size: 2em;
  color: #eee;
  display: block;
}

img:hover {
  cursor: pointer;
}

.img-fluid {
  max-height: 95vh;
}

.btn-nav {
  position: fixed;
  margin: 0;
  padding: 0;
  top: 0;
}

.btn-nav#btn-last, .btn-nav#btn-next {
  height: 100%;
  padding: 49vh 0 0 0;
  width: 125px;
}

.btn-nav#btn-last {
  left: 0;
}

.btn-nav#btn-next {
  right: 0;
}

.btn-nav#btn-close {
  right: 125px;
  left: 125px;
  padding: 20px 50px 20px 0;
}

.btn-nav:hover {
  background-color: rgba(200, 200, 200, 0.5);
  cursor: pointer;
}

.img-thumbnail {
  border-color: #999;
  border-width: 1px;
  border-radius: 0;
  background-color: #eee;
  padding: 3px;
}

@media (max-width: 1000px) {
  .btn-nav#btn-last, .btn-nav#btn-next {
    width: 75px;
  }

  .btn-nav#btn-close {
    right: 75px;
    left: 75px;
    padding: 10px 50px 10px 0;
  }
}

@media (max-width: 750px) {
  .btn-nav#btn-last, .btn-nav#btn-next {
    width: 50px;
  }

  .btn-nav#btn-close {
    display: none;
  }
}
</style>
