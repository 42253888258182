
import { Vue } from 'vue-class-component';

export default class Header extends Vue {

  menu = [
    { label: 'references', routerName: 'reference_list' },
    { label: 'agence', routerName: 'agence' },
    { label: 'contact', routerName: 'contact' }
  ];

}
