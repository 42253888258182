import { AbstractApiParam } from '@/service/ApiService';
import { LegendeRefCollection } from '@/model/LegendeRef';
import { LegendeRefBuilder } from '@/model/builder/LegendeRefBuilder';
import { CollectionDtoInterface } from '@/interface/dto/CollectionDtoInterface';
import { AbstractRepository } from '@/repository/AbstractRepository';
import { ApiParamInterface } from '@/interface/service/ApiParamInterface';
import { LegendeBuilder } from '@/model/builder/LegendeBuilder';
import { LegendeDtoInterface } from '@/interface/dto/LegendeDtoInterface';
import { Legende } from '@/model/Legende';

export class LegendeRepository extends AbstractRepository {

  public static readonly factoryName = 'LegendeRepository';

  public ref(dto?: LegendeRefApiDto) {
    return this.apiService.get('legende', dto)
      .then((data) => {
        return Promise.resolve().then((): LegendeRefCollection => {
          return LegendeRefBuilder.buildCollection(data as CollectionDtoInterface);
        });
      });
  }

  public save(id?: number, dto?: ApiParamInterface): Promise<Legende> {
    return id === undefined
      ? this.add(dto)
      : this.update(id, dto);
  }

  public add(dto?: ApiParamInterface): Promise<Legende> {
    return this.apiService.post('legende', dto)
      .then((data) => {
        return Promise.resolve().then((): Legende => {
          return LegendeBuilder.build(data as LegendeDtoInterface);
        });
      });
  }

  public update(id: number, dto?: ApiParamInterface): Promise<Legende> {
    return this.apiService.put('legende/' + id, dto)
      .then((data) => {
        return Promise.resolve().then((): Legende => {
          return LegendeBuilder.build(data as LegendeDtoInterface);
        });
      });
  }

  public delete(id: number) {
    return this.apiService.delete('legende/' + id);
  }

}

export class LegendeRefApiDto extends AbstractApiParam {}
