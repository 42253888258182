
import { Options, Vue } from 'vue-class-component';
import { ReferenceApiDto, ReferenceRepository } from '@/repository/ReferenceRepository';
import { Reference } from '@/model/Reference';

import Loading from '@/components/Loading.vue';
import Complete from '@/components/reference/Complete.vue';
import { ServiceManager } from '@/service/ServiceManager';

@Options({
  props: {},
  components: {
    Complete,
    Loading
  }
})
export default class Show extends Vue {

  private readonly referenceRepository = ServiceManager.get(ReferenceRepository.factoryName) as ReferenceRepository;

  loading = false;
  find = false;
  reference: Reference = new Reference();

  get id(): number {
    return +this.$route.params.id;
  }

  created() {
    const dto: ReferenceApiDto = new ReferenceApiDto();

    dto.with.push('images.image');
    dto.with.push('legendes.legende');

    this.loading = true;

    this.referenceRepository.find(this.id, dto)
      .then((reference: Reference) => {
        this.reference = reference;
        this.find = true;
      }).finally(() => { this.loading = false; });
  }

}
