import { ApiService } from '@/service/ApiService';
import { ServiceManager } from '@/service/ServiceManager';
import { StoreService } from '@/service/StoreService';
import { StorageService } from '@/service/StorageService';

export function ApiServiceFactory(): ApiService {
  return new ApiService(
    ServiceManager.get(StorageService.user) as StorageService,
    ServiceManager.get(StoreService.factoryName) as StoreService
  );
}
