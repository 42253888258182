<template>
  <nav>
    <ul class="pagination justify-content-end">
      <li class="page-item" v-if="false"><a class="page-link" @click="emit(1);">&laquo;</a></li>
      <li v-for="p in nbPage" :key="p" @click="emit(p);"
          class="page-item" :class="{ active: (page === p) }"
      ><a class="page-link">{{ p }}</a></li>
      <li class="page-item" v-if="false"><a class="page-link" @click="emit(nbPage);">&raquo;</a></li>
    </ul>
  </nav>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  emits: ['change'],
  props: {
    page: Number,
    nbPage: Number
  }
})
export default class Paginate extends Vue {

  page = 0;
  nbPage = 0;

  emit(page: number) {
    // this.page = page;
    this.$emit('change', page);
  }

}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/variables';

.page-link {
  background-color: $gray-700;
  border-color: $gray-800 !important;
  color: white !important;
}

.active > .page-link,
.page-link:hover {
  background-color: $gray-600 !important;
}

li:not(.active) > .page-link:hover {
  cursor: pointer;
}
</style>
