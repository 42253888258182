import { Reference, ReferenceCollection } from '@/model/Reference';
import { ReferenceRepository } from '@/repository/ReferenceRepository';
import { Context } from '@/store/_index';
import { ServiceManager } from '@/service/ServiceManager';

interface State {
  references: Reference[];
}

export default {
  namespaced: true,
  state: () => ({
    references: []
  }),
  mutations: {
    references: (state: State, references: Reference[]) => {
      state.references = references;
    }
  },
  actions: {
    load(context: Context) {
      const referenceRepository = ServiceManager.get(ReferenceRepository.factoryName) as ReferenceRepository;

      referenceRepository.get().then((collection: ReferenceCollection) => {
        context.commit('references', collection.references);
      });
    }
  }
};
