
import { Options, Vue } from 'vue-class-component';

import ModalConfirm from '@/components/ModalConfirm.vue';
import Loading from '@/components/table/Loading.vue';
import Paginate from '@/components/table/Paginate.vue';

import { ReferenceApiDto, ReferenceRepository } from '@/repository/ReferenceRepository';
import { Reference as ReferenceModel, ReferenceCollection } from '@/model/Reference';
import { ServiceManager } from '@/service/ServiceManager';

@Options({
  props: {},
  components: {
    Loading,
    Paginate,
    ModalConfirm
  }
})
export default class List extends Vue {

  private readonly referenceRepository = ServiceManager.get(ReferenceRepository.factoryName) as ReferenceRepository;

  readonly limit = 15;
  loading = false;
  total = -1;
  page = 1;
  references: ReferenceModel[] = [];
  orderBy = 'ordre';

  modalConfirm: { header: string; body: string; param: ReferenceModel | null; show: boolean } = {
    header: '<i class="fas fa-exclamation-circle"></i> <b>Attention</b>',
    body: '',
    param: null,
    show: false
  }

  created() {
    this.find();
  }

  private find() {
    const dto: ReferenceApiDto = new ReferenceApiDto();

    dto.limit = this.limit;
    dto.offset = this.limit * (this.page - 1);
    dto.orderBy = this.orderBy;

    this.loading = true;

    this.referenceRepository.get(dto).then((collection: ReferenceCollection) => {
      this.total = collection.total;
      this.references = collection.references;
    }).finally(() => { this.loading = false; });
  }

  get nbPage() {
    return Math.ceil(this.total / this.limit);
  }

  changePage(page: number) {
    this.page = page;
    this.find();
  }

  del(reference: ReferenceModel) {
    this.modalConfirm.body = 'Etes vous sur de vouloir supprimer <u><b>' + reference.titre + '</b></u> ?';
    this.modalConfirm.param = reference;
    this.modalConfirm.show = true;
  }

  confirm(response: boolean, reference: ReferenceModel) {
    if (response && reference.id) {
      this.referenceRepository.delete(reference.id)
        .then(() => {
          this.find();
        })
        .catch(() => {
          alert('Une erreur s\'est produite, veuillez réessayer ultérieurement');
        });
    }

    this.modalConfirm.show = false;
  }

}
