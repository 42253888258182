// @ts-ignore
import config from '../../env.json';

import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios';
import { verbeHttpEnum } from '@/enum/VerbeHttpEnum';
import { ApiParamInterface } from '@/interface/service/ApiParamInterface';
import { StoreService } from '@/service/StoreService';
import { StorageService } from '@/service/StorageService';

export class ApiService {

  public static readonly factoryName = 'ApiService';

  private readonly userStorageService: StorageService;
  private readonly storeService: StoreService;

  constructor(userStorageService: StorageService, storeService: StoreService) {
    this.userStorageService = userStorageService;
    this.storeService = storeService;
  }

  private get userStore() {
    return this.storeService.user;
  }

  private _refresh = false;

  public get(url: string, param?: ApiParamInterface) {
    return this._send({ method: verbeHttpEnum.GET, url: url, param: param });
  }

  public post(url: string, param?: ApiParamInterface) {
    return this._send({ method: verbeHttpEnum.POST, url: url, param: param });
  }

  public put(url: string, param?: ApiParamInterface) {
    return this._send({ method: verbeHttpEnum.PUT, url: url, param: param });
  }

  public delete(url: string) {
    return this._send({ method: verbeHttpEnum.DELETE, url: url });
  }

  public file(url: string, param?: ApiParamInterface) {
    return this._send({ method: verbeHttpEnum.FILE, url: url, param: param });
  }

  private _generateUrl(url: string, param?: ApiParamInterface) {
    let _param = config.api.debug ? '?XDEBUG_SESSION_START=PHPSTORM' : '';

    if (typeof param !== 'undefined') {
      for (const [key, value] of Object.entries(param.param())) {
        _param = this._generateGetParam(_param, key, value);
      }
    }

    return config.api.uri + url + _param;
  }

  private _generateGetParam(param: string, key: string, value: unknown): string {

    if (typeof value !== 'undefined' && value !== null) {
      if (typeof value === 'object') {
        for (const value2 of Object.values(value as object)) {
          param = this._generateGetParam(param, `${key}[]`, value2);
        }
      } else {
        param += (param === '' ? '?' : '&') + `${key}=${value}`;
      }
    }

    return param;
  }

  private _send(options: optionSend): Promise<unknown> {
    const axiosHeaders = {
      accept: 'application/json',
      'content-type': options.method === verbeHttpEnum.FILE ? 'multipart/form-data' : 'application/json; charset=utf-8',
      'x-xsrf-token': this.userStore.xsrf ?? ''
    };

    const myAxios: AxiosInstance = axios.create({ headers: axiosHeaders, withCredentials: true });

    let response;

    switch (options.method) {
      case verbeHttpEnum.PUT:
        response = myAxios.put(
          this._generateUrl(options.url),
          options.param ? options.param.param() : null,
          { withCredentials: true }
        );
        break;

      case verbeHttpEnum.POST:
      case verbeHttpEnum.FILE:
        response = myAxios.post(
          this._generateUrl(options.url),
          options.param ? options.param.param() : null,
          { withCredentials: true }
        );
        break;

      case verbeHttpEnum.DELETE:
        response = myAxios.delete(
          this._generateUrl(options.url),
          { withCredentials: true }
        );
        break;

      case verbeHttpEnum.GET:
      default:
        response = myAxios.get(
          this._generateUrl(options.url, options.param),
          { withCredentials: true }
        );
        break;
    }

    return response
      .then((_response: AxiosResponse): Promise<unknown> => this._response(_response))
      .catch((_error: AxiosError): Promise<unknown> => this._error(_error, options));
  }

  private _response(response: AxiosResponse): Promise<unknown> {
    return new Promise((resolve, reject) => {
      if (response.status === 204 || typeof response.data === 'object') {
        const data: AxiosResponseData = response.data;

        if (typeof data.data !== 'undefined') {
          resolve(data.data);
        } else {
          resolve();
        }
      } else {
        reject(new Error('Bad response'));
      }
    });
  }

  private async _error(error: AxiosError, options: optionSend): Promise<unknown> {
    let message = 'An error occurred';

    if (!this._refresh && error.response?.status === 401) {
      this._refresh = true;

      const data = await this._send({ method: verbeHttpEnum.POST, url: 'auth/refresh' }) as { xsrf: string };

      this.userStore.extend(data.xsrf);
      this.userStorageService.setItem(this.userStore);

      const result = await this._send(options);

      this._refresh = false;

      return result;
    }

    if (
      typeof error.response === 'object' &&
      typeof error.response.data === 'object' &&
      typeof error.response.data.error === 'object' &&
      typeof error.response.data.error.description === 'string'
    ) {
      message = error.response.data.error.description;
    }

    return new Promise((resolve, reject) => {
      reject(new Error(message));
    });
  }

}

export abstract class AbstractApiParam implements ApiParamInterface {

  with: string[] = [];
  withCount: string[] = [];
  limit: number | null = null;
  offset: number | null = null;
  orderBy: string | null = null;

  param(): {} {
    return {
      with: this.with,
      withCount: this.withCount,
      limit: this.limit,
      offset: this.offset,
      orderBy: this.orderBy
    };
  }

}

interface AxiosResponseData {
  statusCode: number;
  data?: object;
  error?: {
    type?: string;
    description?: string;
  };
}

type optionSend = {
  method: string;
  url: string;
  param?: ApiParamInterface;
  refresh?: boolean;
};
