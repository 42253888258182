
import { Options, Vue } from 'vue-class-component';

import Form from '@/components/form/Photo.vue';

import { PhotoForm } from '@/form/PhotoForm';

@Options({
  components: {
    Form
  }
})
export default class Add extends Vue {

  form: PhotoForm = new PhotoForm();

}
