<template>
  <div>
    <h3 class="h3">En construction</h3>
    <img src="@/assets/img/construction.jpg" class="img-fluid img-thumbnail mt-1" />
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class ComingSoon extends Vue { }
</script>

<style scoped lang="scss">
div {
  text-align: center;
  max-width: 750px;
}

h3 {
  margin-top: 75px;
}

img {
  max-width: 500px;
}
</style>
