import store from '@/store/_index';

export class StoreService {

  public static readonly factoryName = 'StoreService';

  get store() {
    return store;
  }

  get user() {
    return this.store.state.user;
  }

}
