<template>
  <img src="@/assets/img/4_3.png" class="img-fluid" :style="styleCartouche" @mouseover="hover = true" @mouseleave="hover = false" />
  <router-link :to="{ name: 'reference_show', params: { id: reference.id } }" class="rollopaque" :class="{ empty: hover }">
    <div class="cartouche">
      <p v-if="reference.titre">
        Projet :<br />
        <span>{{ reference.titre }}</span>
      </p>
      <p v-if="reference.localisation">
        Localisation :<br />
        <span>{{ reference.localisation }}</span>
      </p>
      <p v-if="reference.etat">
        Etat :<br />
        <span>{{ reference.etat }}</span>
      </p>
    </div>
  </router-link>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Reference } from '@/model/Reference';
import { Image } from '@/model/Image';

@Options({
  props: {
    reference: Reference
  }
})
export default class Miniature extends Vue {

  reference!: Reference;
  hover = false;

  get styleCartouche() {
    let style = '';

    if (this.reference.cartouche instanceof Image) {
      style = 'background-image: url("/images/references/' + this.reference.cartouche.source + '");';
    }

    return style;
  }

}
</script>

<style scoped lang="scss">
img {
  width: 100%;
  background-position: top;
  background-size: cover;
  background-repeat: no-repeat;
}

.rollopaque {
  position: absolute;
  top: 15px;
  bottom: 15px;
  left: 15px;
  right: 15px;
  padding: 5px;
  overflow: hidden;
  background-color: black;
  color: #ffffff;
  font-size: 0.8em;
  opacity: 0.80;
  filter: alpha(opacity=80);
  border: 0;
  cursor: pointer;
  z-index: -1;
}

.rollopaque:hover,
.rollopaque.empty {
  z-index: 50;
}

.rollopaque > .cartouche {
  max-height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  padding: 8px;
  margin: 0;
}

.rollopaque > .cartouche > p {
  text-transform: uppercase;
}

.rollopaque > .cartouche > p > span {
  text-transform: none;
  font-style: italic;
  margin-left: 10px;
}
</style>
