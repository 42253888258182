
import { Options, Vue } from 'vue-class-component';

@Options({
  emits: ['change'],
  props: {
    page: Number,
    nbPage: Number
  }
})
export default class Paginate extends Vue {

  page = 0;
  nbPage = 0;

  emit(page: number) {
    // this.page = page;
    this.$emit('change', page);
  }

}
