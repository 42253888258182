import Photo from '@/views/admin/Photo.vue';
import PhotoList from '@/views/admin/photo/List.vue';
import PhotoAdd from '@/views/admin/photo/Add.vue';

export default {
  name: 'admin_photo',
  path: 'photos',
  component: Photo,
  children: [
    {
      name: 'admin_photo_list',
      path: '',
      component: PhotoList
    },
    {
      name: 'admin_photo_add',
      path: 'add',
      component: PhotoAdd
    }
  ]
};
