<template>
  <ModalConfirm v-if="modalConfirm.show" v-on:confirm="confirm"
                :header="modalConfirm.header" :body="modalConfirm.body" :param="modalConfirm.param" />

  <table class="table table-striped table-hover">
    <thead class="thead-dark">
    <tr>
      <th style="width: 33px" />
      <th>
        Projet
      </th>
      <th>
        Localisation
      </th>
      <th>
        Etat
      </th>
      <th class="action">
        Action
      </th>
    </tr>
    </thead>
    <tbody v-if="loading">
    <Loading :colspan="5" />
    </tbody>
    <tbody v-else>
    <tr v-for="reference in references" v-bind:key="reference.id" :class="{ 'desactive': !reference.actif }">
      <td class="text-center">
        <i v-if="!reference.actif" title="non visible" class="far fa-eye-slash" />
      </td>
      <td>
        {{ reference.titre }}
      </td>
      <td>
        {{ reference.localisation }}
      </td>
      <td>
        {{ reference.etat }}
      </td>
      <td class="action text-right">
        <router-link :to="{ name: 'admin_reference_edit', params: { id: reference.id } }" class="btn btn-sm btn-secondary mr-1">modifier</router-link>
        <button class="btn btn-sm btn-danger" @click="del(reference)">supprimer</button>
      </td>
    </tr>
    </tbody>
  </table>
  <div class="row">
    <div class="col-12">
      <Paginate :page="page" :nbPage="nbPage" @change="changePage" />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import ModalConfirm from '@/components/ModalConfirm.vue';
import Loading from '@/components/table/Loading.vue';
import Paginate from '@/components/table/Paginate.vue';

import { ReferenceApiDto, ReferenceRepository } from '@/repository/ReferenceRepository';
import { Reference as ReferenceModel, ReferenceCollection } from '@/model/Reference';
import { ServiceManager } from '@/service/ServiceManager';

@Options({
  props: {},
  components: {
    Loading,
    Paginate,
    ModalConfirm
  }
})
export default class List extends Vue {

  private readonly referenceRepository = ServiceManager.get(ReferenceRepository.factoryName) as ReferenceRepository;

  readonly limit = 15;
  loading = false;
  total = -1;
  page = 1;
  references: ReferenceModel[] = [];
  orderBy = 'ordre';

  modalConfirm: { header: string; body: string; param: ReferenceModel | null; show: boolean } = {
    header: '<i class="fas fa-exclamation-circle"></i> <b>Attention</b>',
    body: '',
    param: null,
    show: false
  }

  created() {
    this.find();
  }

  private find() {
    const dto: ReferenceApiDto = new ReferenceApiDto();

    dto.limit = this.limit;
    dto.offset = this.limit * (this.page - 1);
    dto.orderBy = this.orderBy;

    this.loading = true;

    this.referenceRepository.get(dto).then((collection: ReferenceCollection) => {
      this.total = collection.total;
      this.references = collection.references;
    }).finally(() => { this.loading = false; });
  }

  get nbPage() {
    return Math.ceil(this.total / this.limit);
  }

  changePage(page: number) {
    this.page = page;
    this.find();
  }

  del(reference: ReferenceModel) {
    this.modalConfirm.body = 'Etes vous sur de vouloir supprimer <u><b>' + reference.titre + '</b></u> ?';
    this.modalConfirm.param = reference;
    this.modalConfirm.show = true;
  }

  confirm(response: boolean, reference: ReferenceModel) {
    if (response && reference.id) {
      this.referenceRepository.delete(reference.id)
        .then(() => {
          this.find();
        })
        .catch(() => {
          alert('Une erreur s\'est produite, veuillez réessayer ultérieurement');
        });
    }

    this.modalConfirm.show = false;
  }

}
</script>

<style scoped lang="scss">
@import "../../../../assets/scss/variables";

.action {
  max-width: 75px;
}

.desactive {
  color: $gray-600;
  font-style: italic;
}
</style>
