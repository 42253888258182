import { AbstractBuilder } from '@/model/builder/_Builder';
import { Legende, LegendeCollection } from '@/model/Legende';
import { LegendeDtoInterface, LegendeRefDtoInterface } from '@/interface/dto/LegendeDtoInterface';
import { LegendeRefBuilder } from '@/model/builder/LegendeRefBuilder';
import { CollectionDtoInterface } from '@/interface/dto/CollectionDtoInterface';

export class LegendeBuilder extends AbstractBuilder {

  static build(data: LegendeDtoInterface): Legende {
    const model: Legende = new Legende();

    if (typeof data.id === 'number') {
      model.id = data.id;
    }

    if (typeof data.legende === 'object') {
      model.legende = LegendeRefBuilder.build(data.legende as LegendeRefDtoInterface);
    }

    if (typeof data.value === 'string') {
      model.value = data.value;
    }

    return model;
  }

  static buildCollection(data: CollectionDtoInterface): LegendeCollection {
    const collection: LegendeCollection = new LegendeCollection();
    const result = data.result as LegendeDtoInterface[];

    Object.values(result).forEach((element: LegendeDtoInterface) => {
      collection.legendes.push(this.build(element));
    });

    if (typeof data.total === 'number') {
      collection.total = data.total;
    }

    return collection;
  }

}
