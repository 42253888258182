<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">

          <div class="modal-header" v-if="header">
            <slot name="header">
              <p v-html="header" />
            </slot>
          </div>

          <div class="modal-body" v-if="body">
            <slot name="body">
              <p v-html="body" />
            </slot>
          </div>

          <div class="modal-footer">
            <slot name="footer">
              <button v-if="buttonOk" class="btn btn-sm" :class="buttonOkClass" @click="$emit('confirm', true, param)">
                {{ buttonOk }}
              </button>
              <button v-if="buttonKo" class="btn btn-sm" :class="buttonKoClass" @click="$emit('confirm', false, param)">
                {{ buttonKo }}
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  emits: ['confirm'],
  props: {
    header: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: 'Etes vous sur ?'
    },
    buttonOk: {
      type: String,
      default: 'Oui'
    },
    buttonKo: {
      type: String,
      default: 'Non'
    },
    buttonOkClass: {
      type: String,
      default: 'btn-success'
    },
    buttonKoClass: {
      type: String,
      default: 'btn-danger'
    },
    param: Object
  }
})
export default class ModalConfirm extends Vue {

  header!: string;
  body!: string;
  buttonOk!: string;
  buttonKo!: string;
  param?: unknown;
  buttonOkClass!: string;
  buttonKoClass!: string;

}
</script>

<style scoped lang="scss">
.modal-container {
  width: 600px;
}

p {
  margin: 0;
  padding: 0;
}
</style>
