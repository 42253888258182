<template>
  <Title title="Références" />

  <router-view />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Title from '@/components/Title.vue';

@Options({
  components: {
    Title
  }
})
export default class Reference extends Vue { }
</script>

<style scoped lang="scss">
</style>
