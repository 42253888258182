<template>
  <Title title="Photo" />

  <ComingSoon v-if="soon" />
  <router-view v-else />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Title from '@/components/Title.vue';
import ComingSoon from '@/components/ComingSoon.vue';

@Options({
  components: {
    Title,
    ComingSoon
  }
})
export default class Photo extends Vue {

  soon = true;

}
</script>

<style scoped lang="scss">
</style>
