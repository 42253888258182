<template>
  <div class="d-flex flex-row bd-highlight mb-3">
    <div class="card" style="width: 18rem;" @click="goTo('admin_reference_new')">
      <div class="card-body">
        <p class="card-text">Ajouter une nouvelle référence.</p>
        <router-link :to="{ name: 'admin_reference_new' }" class="btn btn-secondary">
          <i class="fas fa-plus" /><span>créer</span>
        </router-link>
      </div>
    </div>

    <div class="card" style="width: 18rem;" @click="goTo('admin_reference_edit_list')">
      <div class="card-body">
        <p class="card-text">Editer une référence existante.</p>
        <router-link :to="{ name: 'admin_reference_edit_list' }" class="btn btn-secondary">
          <i class="fas fa-pen" /><span>modifier</span>
        </router-link>
      </div>
    </div>

    <div class="card" style="width: 18rem;" @click="goTo('admin_reference_order')">
      <div class="card-body">
        <p class="card-text">Modifier l'ordre d'affichage.</p>
        <router-link :to="{ name: 'admin_reference_order' }" class="btn btn-secondary">
          <i class="fas fa-sort-amount-down" /><span>trier</span>
        </router-link>
      </div>
    </div>

    <div class="card" style="width: 18rem;" @click="goTo('admin_reference_legende')">
      <div class="card-body">
        <p class="card-text">Editer les légendes.</p>
        <router-link :to="{ name: 'admin_reference_legende' }" class="btn btn-secondary">
          <i class="fas fa-list-ul" /><span>legende</span>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {},
  components: {}
})
export default class Index extends Vue {

  goTo(name: string) {
    this.$router.push({ name: name });
  }

}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/variables';

.card {
  margin: 3px
}

.card:hover {
  cursor: pointer;
  border-color: $secondary;
}

i {
  margin: 0 6px 0 0;
}
</style>
