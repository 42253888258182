
import { Options, Vue } from 'vue-class-component';
import { Image, ImageCollection } from '@/model/Image';
import { ImageApiDto, ImageRepository } from '@/repository/ImageRepository';
import { ServiceManager } from '@/service/ServiceManager';

@Options({
  emits: ['fermer'],
  props: {
    imagesSelected: Array,
    multiple: Boolean
  }
})
export default class Selection extends Vue {

  private readonly imageRepository = ServiceManager.get(ImageRepository.factoryName) as ImageRepository;

  images: Image[] = [];

  imagesSelected!: Image[];
  multiple!: boolean;

  created() {
    const dto: ImageApiDto = new ImageApiDto();

    dto.limit = 100;

    this.imageRepository.get(dto)
      .then((collection: ImageCollection) => {
        this.images = collection.images;
      });
  }

  selected(image: Image) {
    return this.imagesSelected.some((imageSelected: Image) => {
      return imageSelected.id === image.id;
    });
  }

  toggleSelect(image: Image) {
    const index = this.imagesSelected.findIndex((imageSelected: Image) => {
      return imageSelected.id === image.id;
    });

    if (index !== -1) {
      this.imagesSelected.splice(index, 1);
    } else {
      this.imagesSelected.push(image);
    }

    if (!this.multiple) {
      this.$emit('fermer');
    }

  }

}
