import { ReferenceBuilder } from '@/model/builder/ReferenceBuilder';
import { ReferenceDtoInterface } from '@/interface/dto/ReferenceDtoInterface';
import { Reference, ReferenceCollection } from '@/model/Reference';
import { AbstractApiParam } from '@/service/ApiService';
import { CollectionDtoInterface } from '@/interface/dto/CollectionDtoInterface';
import { ApiParamInterface } from '@/interface/service/ApiParamInterface';
import { AbstractRepository } from '@/repository/AbstractRepository';

export class ReferenceRepository extends AbstractRepository {

  public static readonly factoryName = 'ReferenceRepository';

  public get(dto?: ReferenceApiDto) {
    return this.apiService.get('projet', dto)
      .then((data) => {
        return Promise.resolve().then((): ReferenceCollection => {
          return ReferenceBuilder.buildCollection(data as CollectionDtoInterface);
        });
      });
  }

  public find(id: number, dto?: ReferenceApiDto): Promise<Reference> {
    return this.apiService.get('projet/' + id, dto)
      .then((data) => {
        return Promise.resolve().then((): Reference => {
          return ReferenceBuilder.build(data as ReferenceDtoInterface);
        });
      });
  }

  public save(id?: number, dto?: ApiParamInterface): Promise<Reference> {
    return id === undefined
      ? this.add(dto)
      : this.update(id, dto);
  }

  public add(dto?: ApiParamInterface): Promise<Reference> {
    return this.apiService.post('projet', dto)
      .then((data) => {
        return Promise.resolve().then((): Reference => {
          return ReferenceBuilder.build(data as ReferenceDtoInterface);
        });
      });
  }

  public update(id: number, dto?: ApiParamInterface): Promise<Reference> {
    return this.apiService.put('projet/' + id, dto)
      .then((data) => {
        return Promise.resolve().then((): Reference => {
          return ReferenceBuilder.build(data as ReferenceDtoInterface);
        });
      });
  }

  public delete(id: number) {
    return this.apiService.delete('projet/' + id);
  }

}

export class ReferenceApiDto extends AbstractApiParam {

  actif: boolean | null = null;

  param(): {} {
    let actif: number | null = null;

    if (this.actif !== null) {
      actif = this.actif ? 1 : 0;
    }

    return Object.assign({}, super.param(), {
      actif: actif
    });
  }

}
