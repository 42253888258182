
import { Vue } from 'vue-class-component';
import store from '@/store/_index';

import { Agence } from '@/model/Agence';

export default class Contact extends Vue {

  get agence() {
    return store.state.agence as Agence;
  }

  public googleMap() {
    window.open(
      'https://www.google.com/maps/place/11+Rue+F%C3%A9libien,+28000+Chartres/@48.447964,1.4815571,16.75z/data=!4m5!3m4!1s0x47e40c4749554755:0xafb9a25fe2a4647a!8m2!3d48.44849!4d1.482679',
      '_blank'
    );
  }

}
