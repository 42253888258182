
import { Options, Vue } from 'vue-class-component';

import Title from '@/components/Title.vue';

@Options({
  components: {
    Title
  }
})
export default class Reference extends Vue { }
