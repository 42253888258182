
import { Options, Vue } from 'vue-class-component';
import { Image } from '@/model/Image';

@Options({
  emits: ['close'],
  props: {
    images: Array,
    current: Number
  }
})
export default class Caroussel extends Vue {

  images!: Image[];
  current?: number;
  index = 0;

  get image() {
    let image: Image | null = null;

    // this.index = 0;

    if (this.index >= 0) {
      image = this.images[this.index];
    }

    return image instanceof Image ? image : null;
  }

  created() {
    this.index = this.images.findIndex((image: Image) => {
      return image.id === this.current;
    });

    document.addEventListener('keydown', this.keydown);
  }

  keydown(event: KeyboardEvent) {
    switch (event.key) {
      case 'ArrowLeft':
        this.last();
        break;
      case 'ArrowRight':
        this.next();
        break;
      case 'Escape':
        this.$emit('close');
        break;
    }
  }

  last() {
    let index: number = this.images.findIndex((image: Image) => {
      return this.image instanceof Image && image.id === this.image.id;
    }) - 1;

    if (index === -1) {
      index = this.images.length - 1;
    }

    this.index = index;
  }

  next() {
    let index: number = this.images.findIndex((image: Image) => {
      return this.image instanceof Image && image.id === this.image.id;
    }) + 1;

    if (index === this.images.length) {
      index = 0;
    }

    this.index = index;
  }

}

