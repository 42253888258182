
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {},
  components: {}
})
export default class Index extends Vue {

  goTo(name: string) {
    this.$router.push({ name: name });
  }

}
