import { ReferenceImageInterface } from '@/interface/model/ReferenceImageInterface';
import { Reference } from '@/model/Reference';
import { Image } from '@/model/Image';

export class ReferenceImage implements ReferenceImageInterface {

  private _referenceId?: number;
  private _imageId?: number;
  private _reference?: Reference;
  private _image?: Image;
  private _ordre?: number;

  get referenceId(): number | undefined {
    return this._referenceId;
  }

  set referenceId(value: number | undefined) {
    this._referenceId = value;
  }

  get imageId(): number | undefined {
    return this._imageId;
  }

  set imageId(value: number | undefined) {
    this._imageId = value;
  }

  get reference(): Reference | undefined {
    return this._reference;
  }

  set reference(value: Reference | undefined) {
    this._reference = value;
  }

  get image(): Image | undefined {
    return this._image;
  }

  set image(value: Image | undefined) {
    this._image = value;
  }

  get ordre(): number | undefined {
    return this._ordre;
  }

  set ordre(value: number | undefined) {
    this._ordre = value;
  }

}
