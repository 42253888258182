<template>
  <ModalConfirm v-if="modalConfirm" v-on:confirm="confirm"
                buttonKo=""
                buttonOkClass="btn-secondary"
                buttonOk="retour aux références"
                :body="modalBody"
                header="<i class='fas fa-check-circle text-success'></i> <b>Modification de l'ordre des références</b>" />

  <div class="info">
    Info :
    <ul>
      <li>Faire <span class="expo">glisser</span> les lignes pour modifier <span class="expo">l'ordre</span>.</li>
    </ul>
  </div>

  <table class="table table-striped table-hover">
    <thead class="thead-dark">
    <tr>
      <th>
        Projet
      </th>
      <th>
        Localisation
      </th>
      <th>
        Etat
      </th>
    </tr>
    </thead>
    <tbody v-if="loading">
    <Loading :colspan="3" />
    </tbody>
    <tbody v-else>
      <draggable v-model="references" tag="transition-group" item-key="id">
        <template #item="{element}">
          <tr class="pointer">
            <td>
              {{ element.titre }}
            </td>
            <td>
              {{ element.localisation }}
            </td>
            <td>
              {{ element.etat }}
            </td>
          </tr>
        </template>
      </draggable>
    </tbody>
  </table>
  <p class="mt-4">
    <button class="btn btn-success mr-1" :class="{ disabled: saving }" @click="save()">
      <span v-if="saving" class="spinner-border spinner-border-sm"></span>
      Enregistrer
    </button>
  </p>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Draggable from 'vuedraggable';
import Loading from '@/components/table/Loading.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

import { Reference as ReferenceModel, ReferenceCollection } from '@/model/Reference';
import { ReferenceApiDto, ReferenceRepository } from '@/repository/ReferenceRepository';
import { ServiceManager } from '@/service/ServiceManager';

@Options({
  components: {
    Loading,
    Draggable,
    ModalConfirm
  }
})
export default class Order extends Vue {

  private readonly referenceRepository = ServiceManager.get(ReferenceRepository.factoryName) as ReferenceRepository;

  references: ReferenceModel[] = [];
  loading = false;
  saving = false;

  modalConfirm = false;
  modalBody = '';

  created() {
    this.find();
  }

  private find() {
    const dto: ReferenceApiDto = new ReferenceApiDto();

    dto.with.push('images.image');
    dto.with.push('legendes.legende');
    dto.orderBy = 'ordre';

    this.loading = true;

    this.referenceRepository.get(dto).then((collection: ReferenceCollection) => {
      this.references = collection.references;
    }).finally(() => { this.loading = false; });
  }

  public async save() {
    this.saving = true;

    try {
      let changement = false;

      for (const reference of this.references) {
        const index: number = this.references.indexOf(reference);

        if (index !== reference.ordre) {
          changement = true;
          reference.ordre = index;

          console.log(reference);
          console.table(reference.param());

          await this.referenceRepository.save(reference.id, reference);
        }
      }

      this.modalBody = changement
        ? "L'enregistrement a été effectué avec succès."
        : 'Aucun changement a effectuer.';

      // this.modalConfirm = true;
      this.find();
    } finally {
      this.saving = false;
    }
  }

  confirm() {
    this.$router.push({ name: 'admin_reference_index' });
  }

}
</script>

<style scoped lang="scss">
table {
  max-width: 1000px;
}

.info {
  font-style: italic;
  font-size: 0.9em;
}

.expo {
  text-decoration: underline;
  font-weight: bold;
}
</style>
