<template>

  <div class="row">
    <div class="col-12" style="text-align: justify">
      L'agence d'architecture <b>horizon</b> a été créée en 2017 avec l'objectif de <b>vous proposer
      son savoir faire</b> et <b>vous accompagner dans la réalisation de vos projets</b>, avec un intérêt
      particulier pour l'habitat sous toutes ses formes, qu'il soit individuel, groupé ou collectif,
      en réhabilitation comme en neuf.
    </div>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Presentation extends Vue {

}
</script>

<style scoped lang="scss">
</style>
