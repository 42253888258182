<template>
  <div>
    Domaines d'intervention<br/>
    <ul>
      <li>Logement individuel</li>
      <li>Logements groupés</li>
      <li>Logements collectifs</li>
      <li>Bureaux</li>
      <li>Commerces</li>
      <li>Équipements publics</li>
      <li>Permis d'aménager</li>
    </ul>
    En neuf, en rénovation ou réhabilitation<br/>
    <br/>
    Zone d'intervention<br/>
    <br/>
    Sur l'ensemble du département de l'Eure et Loir + départements limitrophes
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Domaine extends Vue {

}
</script>

<style scoped lang="scss">
ul {
  list-style-type: none;
  padding: 5px 0 0 10px;
}

li:before {
  content: '- ';
}

div > div {
  background-color: #222222;
  color: #ffffff;
  padding: 20px;
}
</style>
