<template>
  <form @submit.prevent="submit">
    <input type="file" class="form-control-file" @change="fileAdded" multiple="multiple" ref="inputFile" />

    <div>
      <table class="table table-striped table-hover">
        <tbody>
        <tr v-for="(file, index) in form.files" :key="index">
          <td><img :src="file.src" /></td>
          <td>{{ file.name }}</td>
          <td>{{ octet(file.size) }}</td>
          <td>{{ file }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </form>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import { PhotoForm } from '@/form/PhotoForm';
// import { FileStatusEnum } from '@/enum/FileStatusEnum';
// import { FileTypeEnum } from '@/enum/FileTypeEnum';
// import { DataFile } from '@/dataObject/DataFile';

@Options({
  props: {
    form: PhotoForm
  }
})
export default class Photo extends Vue {

  form!: PhotoForm;

  submit() {
    alert('submit');
  }

  public fileAdded(event: Event) {
    console.log(event);
    // if (event.target.files instanceof FileList) {
    //   event.target.files.forEach((fileUploaded: File) => {
    //     const form = this.form;
    //     const reader: FileReader = new FileReader();
    //     const file: DataFile = new DataFile(fileUploaded);
    //
    //     switch (fileUploaded.type) {
    //       case FileTypeEnum.JPEG:
    //       case FileTypeEnum.JPG:
    //       case FileTypeEnum.PNG:
    //         file.type = fileUploaded.type;
    //
    //         reader.readAsDataURL(fileUploaded);
    //         reader.onload = function() {
    //           if (typeof this.result === 'string') {
    //             // form.files.find((formFile: FileInterface) => {
    //             //   return formFile.name === file.name;
    //             // }).src = this.result;
    //             console.log(file);
    //             file.src = this.result;
    //             file.status = FileStatusEnum.valid;
    //           }
    //         };
    //
    //         this.form.files.push(file);
    //         break;
    //     }
    //   });
    // } else {
    //   alert('empty');
    // }
  }

  octet(value: number) {
    let result = '';
    let unite = '';

    if (value !== null) {
      unite = 'o';

      if (value > 999) {
        value = value / 1000;
        unite = 'ko';

        if (value > 999) {
          value = value / 1000;
          unite = 'Mo';
        }
      }

      result = value.toFixed(2).replace('.', ',') + ' ' + unite;
    }

    return result;
  }

}
</script>

<style scoped lang="scss">
img {
  max-width: 100px;
  max-height: 100px;
}
</style>
