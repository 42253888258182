
import { Options, Vue } from 'vue-class-component';

import Loading from '@/components/table/Loading.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

import { ServiceManager } from '@/service/ServiceManager';
import { LegendeRef } from '@/model/LegendeRef';
import { LegendeRefApiDto, LegendeRepository } from '@/repository/LegendeRepository';
import { LegendeForm } from '@/form/LegendeForm';

@Options({
  components: {
    Loading,
    ModalConfirm
  }
})
export default class Legende extends Vue {

  private readonly legendeRepository = ServiceManager.get(LegendeRepository.factoryName) as LegendeRepository;

  legendes: LegendeRef[] = [];
  legendesForm: LegendeForm[] = [];
  newLegende: LegendeForm = new LegendeForm(new LegendeRef());
  loading = false;
  saving = false;
  editLegende = -1;

  modalConfirm: { header: string; body: string; param: LegendeRef | null; show: boolean } = {
    header: '<i class="fas fa-exclamation-circle"></i> <b>Attention</b>',
    body: '',
    param: null,
    show: false
  }

  created() {
    this.find();
  }

  private init() {
    this.newLegende = new LegendeForm(new LegendeRef());
    this.legendesForm = [];
    this.saving = false;
    this.editLegende = -1;
  }

  private async find() {
    this.init();

    const dto = new LegendeRefApiDto();

    dto.orderBy = 'label';
    dto.withCount.push('projets');

    this.loading = true;

    const legendeCollection = await this.legendeRepository.ref(dto);

    for (const legende of legendeCollection.legendes) {
      this.legendesForm.push(new LegendeForm(legende));
    }

    this.legendes = legendeCollection.legendes;

    this.loading = false;
  }

  public cancel(form: LegendeForm) {
    form.value = form.legende.label;

    this.editLegende = -1;
  }

  public async save(form: LegendeForm) {
    if (!this.saving) {
      if (form.isValid()) {
        form.legende.label = form.value;

        this.saving = true;

        try {
          await this.legendeRepository.save(form.legende.id, form);
          await this.find();
        } catch (err) {
          alert('Une erreur s\'est produite, veuillez réessayer ultérieurement');
        }
      }
    }
  }

  del(legende: LegendeRef) {
    this.modalConfirm.body = 'Etes vous sur de vouloir supprimer <u><b>' + legende.label + '</b></u> ?';
    this.modalConfirm.param = legende;
    this.modalConfirm.show = true;
  }

  public async confirm(response: boolean, legende: LegendeRef) {
    if (response && legende.id) {
      try {
        await this.legendeRepository.delete(legende.id);
        this.find();
      } catch (err) {
        alert('Une erreur s\'est produite, veuillez réessayer ultérieurement');
      }
    }

    this.modalConfirm.show = false;
  }

}
