<template>
  <h1 class="h1">{{ title }}</h1>
  <hr />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    title: String
  }
})
export default class Title extends Vue {

  title = 'Title';

}
</script>

<style scoped lang="scss">
h1.h1 {
  font-size: 2rem;
  margin: 0 0 0 6px;
}

hr {
  margin: 0 0 20px 0;
}
</style>
