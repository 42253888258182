import { AbstractModel, AbstractModelCollection } from '@/model/_Model';
import { LegendeRef } from '@/model/LegendeRef';

export class Legende extends AbstractModel {

  private _id?: number;
  private _value?: string;
  private _legende?: LegendeRef

  get id(): number | undefined {
    return this._id;
  }

  set id(value: number | undefined) {
    this._id = value;
  }

  get label(): string | undefined {
    return this.legende ? this.legende.label : undefined;
  }

  get value(): string | undefined {
    return this._value;
  }

  set value(value: string | undefined) {
    this._value = value;
  }

  get legende(): LegendeRef | undefined {
    return this._legende;
  }

  set legende(value: LegendeRef | undefined) {
    this._legende = value;
  }

}

export class LegendeCollection extends AbstractModelCollection {

  private _legendes: Legende[] = [];

  get legendes(): Legende[] {
    return this._legendes;
  }

  set legendes(value: Legende[]) {
    this._legendes = value;
  }

}
