<template>
  <div class="bg">
    <div class="row demarche">
      <div class="col-12 d_titre">
        La démarche de projet
      </div>

      <div class="col-12 col-md-6 d_rub">
        <p>
          <span>1- Répondre au programme</span>
          La démarche de conception débute par une écoute et <b>compréhension des besoins
          et des désirs</b> de notre client pour lui proposer des <b>espaces adaptés et de
          qualité.</b>
        </p>
        <p>
          <span>2- Respecter les contraintes</span>
          Pour qu'un projet aboutisse, l'ensemble des contraintes doivent être prises en
          compte, qu'il s'agisse des contraintes économiques, réglementaires ou techniques
        </p>
        <p>
          <span>3- Réduire l'impact environnemental du projet</span>
          Le secteur du bâtiment a un impact considérable sur l'environnement. C'est
          pourquoi nous recherchons systématiquement à <b>réduire l'incidence de nos
          projets</b>, en ayant une réflexion sur les <b>matériaux de construction,
          l'optimisation des surfaces et une organisation «&nbsp;bioclimatique&nbsp;»</b> des
          espaces.
        </p>
      </div>
      <div class="col-12 col-md-6 d_rub">
        <p>
          <span>4- Simplifier les besoins techniques</span>
          Une conception globale et une vision générale du projet permettent de résoudre
          un grand nombre de contraintes simplement, sans avoir recours à des artifices
          techniques coûteux à l'achat et à l'entretien.
        </p>
        <p>
          <span>5- Insérer le projet dans son environnement</span>
          Un projet réussi n'est pas seulement un projet fonctionnel et centré sur
          lui-même mais un projet qui <b>s'insère avec cohérence dans son
          environnement.</b>
        </p>
      </div>
    </div>

  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Demarche extends Vue {

}
</script>

<style scoped lang="scss">
.bg {
  background-color: #f0f0f0;
  padding: 10px;
}

.d_titre {
  font-weight: bold;
  font-size: 20px;
  margin-bottom: 10px;
}

.d_rub > p {
  padding-left: 20px;
  padding-right: 20px;
}

.d_rub > p > span {
  font-weight: bold;
  font-size: 16px;
  display: block;
  text-align: left;
}

.demarche {
  text-align: justify;
}
</style>
