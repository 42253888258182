<template>
  <Sidebar />
  <AuthForm v-if="!authenticated" />
  <div v-if="authenticated" class="content" :class="{ minified: minified }">
    <router-view />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import store from '@/store/_index';

import AuthForm from '@/components/form/Auth.vue';
import Sidebar from '@/components/Sidebar.vue';
import { ServiceManager } from '@/service/ServiceManager';
import { StoreService } from '@/service/StoreService';

@Options({
  props: {},
  components: {
    AuthForm,
    Sidebar
  }
})
export default class Admin extends Vue {

  private readonly storeService: StoreService = ServiceManager.get(StoreService.factoryName) as StoreService;

  public get userStore() {
    return this.storeService.user;
  }

  get minified(): boolean {
    return store.state.minified as boolean;
  }

  get authenticated(): boolean {
    return this.userStore.authenticated() && !this.userStore.old();
  }

}
</script>

<style scoped lang="scss">
.content {
  margin-left: 250px;
  padding: 65px 20px;
}

.content.minified {
  margin-left: 60px;
}
</style>
