<template>
  <div class="titlebar">
    <router-link :to="{ name: 'admin' }" class="title">
      <img src="@/assets/img/logo.png" class="img-fluid bg-light" style="max-height: 30px;" />
    </router-link>

    <div class="usermenu">

      <p style="margin-bottom: 5px;">
        <a href="/">retourner sur le site</a>
        <button v-if="authenticated" class="btn btn-sm btn-secondary" @click="toggleProfil()">
          <span>{{ userStore.user.email ?? '' }}</span>
          <i class="ml-1 fas" :class="subprofil ? 'fa-chevron-up' : 'fa-chevron-down'" />
        </button>
      </p>

      <div class="subprofil bg-secondary float-right text-left" v-show="subprofil" @click="toggleProfil()">
        <a href="#">
          <i class="fas fa-user-cog" /> Mon profil
        </a>
        <a href="#" title="Déconnexion" class="logout" @click="signout()">
          <i class="fas fa-sign-out-alt" /> Déconnexion
        </a>
      </div>
    </div>

  </div>

  <div v-if="authenticated" class="sidebar" :class="{ minified: minified }">

    <div class="menubar">
      <div class="menu">
        <router-link :to="{ name: 'admin_reference_index' }">
          <i class="fas fa-building" /><span v-if="!minified">Références</span>
        </router-link>
        <div class="submenu" v-if="!minified && referenceOpen">
          <router-link :to="{ name: 'admin_reference_new' }">
            <i class="fas fa-plus" /><span>créer</span>
          </router-link>
          <router-link :to="{ name: 'admin_reference_edit_list' }">
            <i class="fas fa-pen" /><span>modifier</span>
          </router-link>
          <router-link :to="{ name: 'admin_reference_order' }">
            <i class="fas fa-sort-amount-down" /><span>trier</span>
          </router-link>
          <router-link :to="{ name: 'admin_reference_legende' }">
            <i class="fas fa-list-ul" /><span>legende</span>
          </router-link>
        </div>
      </div>
      <div class="menu">
        <router-link :to="{ name: 'admin_photo_list' }">
          <i class="far fa-image" /><span v-if="!minified">Photos</span>
        </router-link>
      </div>
    </div>

    <div class="minify" @click="toggleMinified()">
      <i class="fas" :class="minified ? 'fa-angle-double-right' : 'fa-angle-double-left'" />
      <span v-if="!minified">{{ minifiedLabel }}</span>
    </div>

  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import store from '@/store/_index';
import { StoreService } from '@/service/StoreService';
import { ServiceManager } from '@/service/ServiceManager';
import { AuthService } from '@/service/AuthService';

@Options({
  props: {},
  components: {}
})
export default class Sidebar extends Vue {

  private readonly storeService: StoreService = ServiceManager.get(StoreService.factoryName) as StoreService;
  private readonly authService: AuthService = ServiceManager.get(AuthService.factoryName) as AuthService;

  subprofil = false;
  toggleTimeout = 0;

  public get referenceOpen(): boolean {
    return this.$router.currentRoute.value.matched.some((route) => {
      return route.name === 'admin_reference';
    });
  }

  public get userStore() {
    return this.storeService.user;
  }

  public get minified(): boolean {
    return store.state.minified as boolean;
  }

  public get authenticated(): boolean {
    return this.userStore.authenticated() && !this.userStore.old();
  }

  public get minifiedLabel(): string {
    return this.minified ? '' : 'Masquer la barre';
  }

  public toggleMinified() {
    store.commit('toggleMinified');
  }

  public toggleProfil() {
    this.subprofil = !this.subprofil;

    clearTimeout(this.toggleTimeout);

    if (this.subprofil) {
      this.toggleTimeout = setTimeout(() => {
        this.subprofil = false;
        this.toggleTimeout = 0;
      }, (15 * 1000)); // 15 secondes
    }
  }

  public signout() {
    this.authService.signout();
  }

}
</script>

<style scoped lang="scss">
@import "src/assets/scss/admin/sidebar";
</style>
