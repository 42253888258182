import { AbstractModel, AbstractModelCollection } from '@/model/_Model';
import { Reference } from '@/model/Reference';
import { ImageInterface } from '@/interface/model/ImageInterface';

export class Image extends AbstractModel implements ImageInterface {

  id?: number;
  source?: string;
  references: Reference[] = [];

}

export class ImageCollection extends AbstractModelCollection {

  private _images: Image[] = [];

  get images(): Image[] {
    return this._images;
  }

  set images(value: Image[]) {
    this._images = value;
  }

}
