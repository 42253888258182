<template>
  <header class="container">
    <div class="row d-md-flex">
      <div class="col-md-3 col-lg-6">
        <router-link :to="{ name: 'home' }">
          <img src="@/assets/img/logo.png" class="img-fluid" />
        </router-link>
      </div>
    </div>

    <div class="row d-md-flex menu">
      <div class="offset-md-3 offset-lg-6 col-md-9 col-lg-6">
        <div class="row">
          <router-link v-for="(link, index) in menu" :key="index" :to="{ name: link.routerName }" class="col-4 submenu">
            <span>{{ link.label }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </header>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Header extends Vue {

  menu = [
    { label: 'references', routerName: 'reference_list' },
    { label: 'agence', routerName: 'agence' },
    { label: 'contact', routerName: 'contact' }
  ];

}
</script>

<style scoped lang="scss">
@import "../assets/scss/variables";

header a, header a:hover {
  color: black;
  text-decoration: none;
}

img {
  max-height: 45px;
}

.h-titre {
  font-weight: bold;
  font-size: 40px;
}
.h-titre > span {
  color: $primary;
}

.menu {
  margin-top: -20px;
  margin-right: 0;
}

.submenu {
  text-align: center;
  padding-left: 0px;
  padding-right: 0px;
  border-bottom: solid 1px #222222;
}

.submenu span {
  display: block;
  padding: 2px 10px;
  width: 100%;
}

.submenu:not(.router-link-exact-active):hover {
  cursor: pointer;
  border-color: #bada55;
  color: #bada55 !important;
}

.submenu.router-link-active {
  color: #ffffff;
  background-color: #222222;
  width: 100%;
}

@media (max-width: 576px) {
  .menu {
    margin-top: 25px;
  }

  .submenu a {
    padding-top: 4px;
    padding-bottom: 4px;
  }
}
</style>
