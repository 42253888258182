import { UserInterface } from '@/interface/model/UserInterface';
import { AbstractModelCollection } from '@/model/_Model';

export class User implements UserInterface {

  id?: number;
  name?: string;
  email?: string;

}

export class UserCollection extends AbstractModelCollection {

  private _users: User[] = [];

  get users(): User[] {
    return this._users;
  }

  set users(value: User[]) {
    this._users = value;
  }

}

export type UserType = {
  id?: number | null;
  name?: string | null;
  email?: string | null;
}
