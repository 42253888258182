import { AbstractApiParam } from '@/service/ApiService';
import { CollectionDtoInterface } from '@/interface/dto/CollectionDtoInterface';
import { ImageCollection } from '@/model/Image';
import { ImageBuilder } from '@/model/builder/ImageBuilder';
import { AbstractRepository } from '@/repository/AbstractRepository';

export class ImageRepository extends AbstractRepository {

  public static readonly factoryName = 'ImageRepository';

  public get(dto?: ImageApiDto) {
    return this.apiService.get('image', dto)
      .then((data) => {
        return Promise.resolve().then((): ImageCollection => {
          return ImageBuilder.buildCollection(data as CollectionDtoInterface);
        });
      });
  }

  public delete(id: number) {
    return this.apiService.delete('image/' + id);
  }

}

export class ImageApiDto extends AbstractApiParam {}
