export class StorageService {

  public static readonly user = 'UserStorageService';

  private readonly storage: Storage;
  private readonly defaultKey: string = 'ha';
  private readonly encoded: boolean = true;

  constructor(option: SessionServiceOptionInterface) {
    let storage = option.storage;

    if (typeof storage === 'undefined') {
      storage = window.localStorage;
    }

    if (typeof option.key === 'string') {
      this.defaultKey = option.key;
    }

    if (typeof option.encoded === 'boolean') {
      this.encoded = option.encoded;
    }

    this.storage = storage;
  }

  private static _encode(data: DataSessionInterface, encoded: boolean): string {
    let dataEncoded = JSON.stringify(data.toSave());

    if (encoded) {
      dataEncoded = window.btoa(dataEncoded);
    }

    return dataEncoded;
  }

  private static _decode(json: string, encoded: boolean): {} {
    let data = {};

    if (json !== '' && json !== null) {

      if (encoded) {
        json = window.atob(json);
      }

      data = JSON.parse(json);
    }

    return data;
  }

  public getItem(key?: string) {
    if (typeof key === 'undefined') {
      key = this.defaultKey;
    }

    return StorageService._decode(this.storage.getItem(key) as string, this.encoded);
  }

  public setItem(data: DataSessionInterface, key?: string) {
    if (typeof key === 'undefined') {
      key = this.defaultKey;
    }

    this.storage.setItem(key, StorageService._encode(data, this.encoded));
  }

  public removeItem(key?: string) {
    if (typeof key === 'undefined') {
      key = this.defaultKey;
    }

    this.storage.removeItem(key);
  }

}

export interface DataSessionInterface {
  toSave(): {};
}

export interface SessionServiceOptionInterface {
  storage?: Storage;
  key?: string;
  encoded?: boolean;
}
