import { Agence } from '@/model/Agence';

export default new Agence({
  numero: 11,
  rue: 'rue Félibien',
  codePostal: '28000',
  ville: 'Chartres',
  telephone: '09-87-76-54-55',
  email: 'contact@horizon-architecture.fr'
});
