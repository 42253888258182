import { FormInterface } from '@/interface/form/FormInterface';
import { FileInterface } from '@/interface/form/FileInterface';

export class PhotoForm implements FormInterface {

  files: FileInterface[] = [];

  param(): {} {
    return {};
  }

  isValid(): boolean {
    return false;
  }

}
