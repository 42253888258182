
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    colspan: Number
  }
})
export default class Loading extends Vue {

  colspan = 1;

}
