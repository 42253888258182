
import { Options, Vue } from 'vue-class-component';

import Draggable from 'vuedraggable';
import SelectionImage from '@/components/image/Selection.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

import { ReferenceForm } from '@/form/ReferenceForm';
import { LegendeRef, LegendeRefCollection } from '@/model/LegendeRef';
import { LegendeRepository } from '@/repository/LegendeRepository';
import { Legende } from '@/model/Legende';
import { Image } from '@/model/Image';

import { ReferenceRepository } from '@/repository/ReferenceRepository';
import { ServiceManager } from '@/service/ServiceManager';

@Options({
  props: {
    form: ReferenceForm
  },
  components: {
    Draggable,
    SelectionImage,
    ModalConfirm
  }
})
export default class Reference extends Vue {

  private readonly legendeRepository = ServiceManager.get(LegendeRepository.factoryName) as LegendeRepository;
  private readonly referenceRepository = ServiceManager.get(ReferenceRepository.factoryName) as ReferenceRepository;

  form!: ReferenceForm;
  legendesRef: LegendeRef[] = [];
  prev: Image | null = null;
  selectionImage = false;
  saving = false;
  editLegendes = false;

  modalConfirm = false;
  newRoute = false;

  created() {
    this.newRoute = this.$router.currentRoute.value.name === 'admin_reference_new';

    if (this.form.legendes.length === 0) {
      this.addLegende();
    }

    this.legendeRepository.ref()
      .then((collection: LegendeRefCollection) => {
        this.legendesRef = collection.legendes;
      });
  }

  delLegende(index: number) {
    this.form.legendes.splice(index, 1);
  }

  addLegende() {
    this.form.legendes.push(new Legende());
  }

  submit() {
    if (!this.saving) {
      if (this.form.isValid()) {
        this.saving = true;

        this.referenceRepository.save(this.form.id, this.form)
          .then(() => {
            this.modalConfirm = true;
          })
          .catch(() => {
            alert('Une erreur s\'est produite, veuillez réessayer ultérieurement');
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        alert('Une erreur s\'est produite, veuillez réessayer ultérieurement');
      }
    }
  }

  togglePrev(image: Image) {
    if (this.prev === image) {
      this.closePrev();
    } else {
      this.prev = image;
    }
  }

  closePrev() {
    this.prev = null;
  }

  legendesRefAvailable(index: number) {
    return this.legendesRef.filter((legendeRef: LegendeRef) => {
      return !this.form.legendes.some((legende: Legende, i: number) => {
        return index !== i && legende.legende && legende.legende.id === legendeRef.id;
      });
    });
  }

  confirm() {
    this.$router.push({ name: 'admin_reference_list' });
  }

}
