
import { Options, Vue } from 'vue-class-component';

import Form from '@/components/form/Reference.vue';
import { ReferenceForm } from '@/form/ReferenceForm';

@Options({
  components: {
    Form
  }
})
export default class New extends Vue {

  form: ReferenceForm = new ReferenceForm();

}
