import Reference from '@/views/admin/Reference.vue';
import ReferenceIndex from '@/views/admin/reference/Index.vue';
import ReferenceEditIndex from '@/views/admin/reference/Edit.vue';
import ReferenceEditList from '@/views/admin/reference/edit/List.vue';
import ReferenceEdit from '@/views/admin/reference/edit/Edit.vue';
import ReferenceNew from '@/views/admin/reference/New.vue';
import ReferenceLegende from '@/views/admin/reference/Legende.vue';
import ReferenceOrder from '@/views/admin/reference/Order.vue';

export default {
  name: 'admin_reference',
  path: 'references',
  component: Reference,
  children: [
    {
      name: 'admin_reference_index',
      path: '',
      component: ReferenceIndex
    },
    {
      name: 'admin_reference_list',
      path: 'edit',
      component: ReferenceEditIndex,
      children: [
        {
          name: 'admin_reference_edit_list',
          path: '',
          component: ReferenceEditList
        },
        {
          name: 'admin_reference_edit',
          path: 'edit/:id',
          component: ReferenceEdit
        }
      ]
    },
    {
      name: 'admin_reference_order',
      path: 'order',
      component: ReferenceOrder
    },
    {
      name: 'admin_reference_new',
      path: 'new',
      component: ReferenceNew
    },
    {
      name: 'admin_reference_legende',
      path: 'legende',
      component: ReferenceLegende
    }
  ]
};
