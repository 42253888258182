<template>
  <img src="@/assets/img/logo.png" class="img-fluid logo" />
  <div v-if="check">
    <p class="mt-5 mb-4">
      Veuillez patientez pendant que nous vérifions vos accès.
      <span class="mb-5">
        Ce processus est automatique. Vous allez rapidement être rédirigé vers le contenu souhaité.
      </span>
      <img src="@/assets/img/loading.gif" class="img-fluid" />
    </p>
  </div>
  <div v-else>
    <p class="mt-5 mb-4">
      Cet accès est réservé à l'équipe <b>Horizon architecture</b>
    </p>
    <form class="form-signin" method="post" v-on:submit.prevent="submit">
      <p v-if="error" class="text-danger">
        {{ error }}
      </p>
      <p>
        <input type="text" name="email" autocomplete="off" class="form-control mb-1" placeholder="Email" v-model="form.email" />
        <input type="password" name="password" autocomplete="off" class="form-control" placeholder="Mot de passe" v-model="form.password" />
      </p>
      <p>
        <button class="btn btn-primary btn-block" type="submit" :disabled="connexion">{{ connexion ? 'identification en cours' : 'Connexion' }}</button>
      </p>
    </form>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import { AuthForm } from '@/form/AuthForm';
import { AuthService } from '@/service/AuthService';
import { ServiceManager } from '@/service/ServiceManager';
import store from '@/store/_index';

@Options({})
export default class Auth extends Vue {

  private authService: AuthService = ServiceManager.get(AuthService.factoryName) as AuthService;

  check = false;

  form: AuthForm = new AuthForm();
  connexion = false;
  error = false;

  get userStore() {
    return store.state.user;
  }

  created() {
    this.verify();
  }

  public async submit() {
    if (!(await this.authService.authByForm(this.form))) {
      alert('Error');
    }
  }

  public async verify() {
    if (this.userStore.old()) {
      this.check = true;
      await this.authService.verify();
      this.check = false;
    }
  }

}
</script>

<style scoped lang="scss">
img.logo {
  display: block;
  max-width: 333px;
  margin: 8em auto 0 auto;
}

p {
  text-align: center;
}

p > span {
  display: block;
  font-size: 0.8em;
  font-style: italic;
}

form {
  margin: auto;
  height: 250px;
  max-width: 300px;
}

h1 {
  padding-top: 150px;
}
</style>
