<template>
  <router-view />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({ })
export default class Edit extends Vue { }
</script>

<style scoped lang="scss">
</style>
