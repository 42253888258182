import { AuthRepository } from '@/repository/AuthRepository';
import { AuthForm } from '@/form/AuthForm';
import { UserRepository } from '@/repository/UserRepository';
import { StorageService } from '@/service/StorageService';
import { StoreService } from '@/service/StoreService';

export class AuthService {

  public static readonly factoryName = 'AuthService';

  private readonly userStorageService: StorageService;
  private readonly storeService: StoreService;
  private readonly authRepository: AuthRepository;
  private readonly userRepository: UserRepository;

  constructor(
    userStorageService: StorageService,
    storeService: StoreService,
    authRepository: AuthRepository,
    userRepository: UserRepository
  ) {
    this.userStorageService = userStorageService;
    this.storeService = storeService;
    this.authRepository = authRepository;
    this.userRepository = userRepository;
  }

  private get userStore() {
    return this.storeService.user;
  }

  public async authByForm(authForm: AuthForm) {
    try {
      this.userStore.xsrf = await this.authRepository.auth(authForm);
      this.userStore.user = await this.userRepository.me();
      this.userStore.extend();

      this.saveInSession();
    } catch (error) {
      return false;
    }

    return true;
  }

  public async verify() {
    let checked = false;

    if (this.userStore.old()) {
      checked = await this.authRepository.verify() as boolean;

      if (checked) {
        this.userStore.extend();

        this.saveInSession();
      } else {
        this.signout();
      }
    }

    return checked;
  }

  public async refresh() {
    this.userStore.xsrf = await this.authRepository.refresh();
    this.userStore.extend();

    this.saveInSession();
  }

  public saveInSession() {
    this.userStorageService.setItem(this.userStore);
  }

  public signout() {
    this.userStore.signout();
    this.userStorageService.removeItem();
  }

}
