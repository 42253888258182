
import { Options, Vue } from 'vue-class-component';
import store from '@/store/_index';
import { StoreService } from '@/service/StoreService';
import { ServiceManager } from '@/service/ServiceManager';
import { AuthService } from '@/service/AuthService';

@Options({
  props: {},
  components: {}
})
export default class Sidebar extends Vue {

  private readonly storeService: StoreService = ServiceManager.get(StoreService.factoryName) as StoreService;
  private readonly authService: AuthService = ServiceManager.get(AuthService.factoryName) as AuthService;

  subprofil = false;
  toggleTimeout = 0;

  public get referenceOpen(): boolean {
    return this.$router.currentRoute.value.matched.some((route) => {
      return route.name === 'admin_reference';
    });
  }

  public get userStore() {
    return this.storeService.user;
  }

  public get minified(): boolean {
    return store.state.minified as boolean;
  }

  public get authenticated(): boolean {
    return this.userStore.authenticated() && !this.userStore.old();
  }

  public get minifiedLabel(): string {
    return this.minified ? '' : 'Masquer la barre';
  }

  public toggleMinified() {
    store.commit('toggleMinified');
  }

  public toggleProfil() {
    this.subprofil = !this.subprofil;

    clearTimeout(this.toggleTimeout);

    if (this.subprofil) {
      this.toggleTimeout = setTimeout(() => {
        this.subprofil = false;
        this.toggleTimeout = 0;
      }, (15 * 1000)); // 15 secondes
    }
  }

  public signout() {
    this.authService.signout();
  }

}
