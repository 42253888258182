
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {},
  components: {}
})
export default class Notfound extends Vue {

}
