
import { Options, Vue } from 'vue-class-component';
import store from '@/store/_index';

@Options({
  props: {},
  components: {}
})
export default class Home extends Vue {

  get user() {
    return store.state.user;
  }

}
