export abstract class AbstractModel {

}

export abstract class AbstractModelCollection {

  private _total = -1;

  get total(): number {
    return this._total;
  }

  set total(value: number) {
    this._total = value;
  }

}
