import Admin from '@/views/Admin.vue';

import Home from '@/views/admin/Home.vue';
import AdminNotfound from '@/views/admin/Notfound.vue';

import reference from './reference';
import photo from './photo';

export default {
  name: '_admin',
  path: '/_admin_/',
  component: Admin,
  children: [
    {
      name: 'admin',
      path: '',
      component: Home
    },
    reference,
    photo,
    {
      name: 'admin_404',
      path: '/admin/:pathMatch(.*)*',
      component: AdminNotfound
    }
  ]
};
