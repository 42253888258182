<template>
  <Header class="header" />
  <div class="container content">
    <router-view />
  </div>
  <Footer class="footer" />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

// import '@/assets/scss/front.scss';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

@Options({
  components: {
    Header,
    Footer
  }
})
export default class Front extends Vue {

}
</script>

<style scoped lang="scss">

header {
  margin: 20px auto 50px auto;
}

.header, .footer {
  font-family: 'futura', sans-serif;
}

.content {
  font-family: 'Jura', sans-serif;
  margin-bottom: 100px;
}
</style>
