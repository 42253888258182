
import { Options, Vue } from 'vue-class-component';

// import '@/assets/scss/front.scss';

import Header from '@/components/Header.vue';
import Footer from '@/components/Footer.vue';

@Options({
  components: {
    Header,
    Footer
  }
})
export default class Front extends Vue {

}
