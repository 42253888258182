
import { Options, Vue } from 'vue-class-component';

import ModalConfirm from '@/components/ModalConfirm.vue';
import Loading from '@/components/Loading.vue';
import Paginate from '@/components/table/Paginate.vue';

import { Image, ImageCollection } from '@/model/Image';
import { ImageRepository, ImageApiDto } from '@/repository/ImageRepository';
import { Reference } from '@/model/Reference';
import { ServiceManager } from '@/service/ServiceManager';

@Options({
  components: {
    Loading,
    ModalConfirm,
    Paginate
  }
})
export default class List extends Vue {

  private readonly imageRepository = ServiceManager.get(ImageRepository.factoryName) as ImageRepository;

  readonly limit = 15;
  total = -1;
  page = 1;
  loading = false;

  images: Image[] = [];

  modalConfirm: { header: string; body: string; param: Image | null; show: boolean } = {
    header: '<i class="fas fa-exclamation-circle"></i> <b>Etes vous sur de vouloir supprimer cette photo ?</b>',
    body: '',
    param: null,
    show: false
  }

  get nbPage() {
    return Math.ceil(this.total / this.limit);
  }

  created() {
    this.find();
  }

  changePage(page: number) {
    this.page = page;
    this.find();
  }

  find() {
    const dto: ImageApiDto = new ImageApiDto();

    dto.limit = this.limit;
    dto.offset = this.limit * (this.page - 1);
    dto.with.push('projets.projet');

    this.loading = true;

    this.imageRepository.get(dto)
      .then((collection: ImageCollection) => {
        this.total = collection.total;
        this.images = collection.images;
      }).finally(() => { this.loading = false; });
  }

  edit() {
    alert('coming soon');
  }

  del(image: Image) {
    const img = '<img class="img-fluid img-thumbnail" style="max-width: 200px;" src="/images/references/' + image.source + '" />';
    let album = 'Références : <ul>';

    image.references.forEach((reference: Reference) => {
      album += '<li>' + reference.titre + '</li>';
    });
    album += '</ul>';

    this.modalConfirm.body = '<div class="row"><div class="col-6">' + img + '</div><div class="col-6">' + album + '</div></div>';
    this.modalConfirm.param = image;
    this.modalConfirm.show = true;
  }

  confirm(response: boolean, image: Image) {
    if (response && image.id) {
      this.imageRepository.delete(image.id)
        .then(() => {
          // this.find();
        })
        .catch(() => {
          alert('Une erreur s\'est produite, veuillez réessayer ultérieurement');
        });
    }

    this.modalConfirm.show = false;
  }

}
