import { AbstractBuilder } from '@/model/builder/_Builder';
import { LegendeRef, LegendeRefCollection } from '@/model/LegendeRef';
import { LegendeRefDtoInterface } from '@/interface/dto/LegendeDtoInterface';
import { CollectionDtoInterface } from '@/interface/dto/CollectionDtoInterface';

export class LegendeRefBuilder extends AbstractBuilder {

  static build(data: LegendeRefDtoInterface): LegendeRef {
    const model: LegendeRef = new LegendeRef();

    if (typeof data.id === 'number') {
      model.id = data.id;
    }

    if (typeof data.label === 'string') {
      model.label = data.label;
    }

    if (typeof data.projets_count === 'number') {
      model.nbProjet = data.projets_count;
    } else if (typeof data.projets_count === 'string') {
      model.nbProjet = parseInt(data.projets_count);
    }

    return model;
  }

  static buildCollection(data: CollectionDtoInterface): LegendeRefCollection {
    const collection: LegendeRefCollection = new LegendeRefCollection();
    const result = data.result as LegendeRefDtoInterface[];

    Object.values(result).forEach((element: LegendeRefDtoInterface) => {
      collection.legendes.push(this.build(element));
    });

    if (typeof data.total === 'number') {
      collection.total = data.total;
    }

    return collection;
  }

}
