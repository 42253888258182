
import { Options, Vue } from 'vue-class-component';

import Draggable from 'vuedraggable';
import Loading from '@/components/table/Loading.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

import { Reference as ReferenceModel, ReferenceCollection } from '@/model/Reference';
import { ReferenceApiDto, ReferenceRepository } from '@/repository/ReferenceRepository';
import { ServiceManager } from '@/service/ServiceManager';

@Options({
  components: {
    Loading,
    Draggable,
    ModalConfirm
  }
})
export default class Order extends Vue {

  private readonly referenceRepository = ServiceManager.get(ReferenceRepository.factoryName) as ReferenceRepository;

  references: ReferenceModel[] = [];
  loading = false;
  saving = false;

  modalConfirm = false;
  modalBody = '';

  created() {
    this.find();
  }

  private find() {
    const dto: ReferenceApiDto = new ReferenceApiDto();

    dto.with.push('images.image');
    dto.with.push('legendes.legende');
    dto.orderBy = 'ordre';

    this.loading = true;

    this.referenceRepository.get(dto).then((collection: ReferenceCollection) => {
      this.references = collection.references;
    }).finally(() => { this.loading = false; });
  }

  public async save() {
    this.saving = true;

    try {
      let changement = false;

      for (const reference of this.references) {
        const index: number = this.references.indexOf(reference);

        if (index !== reference.ordre) {
          changement = true;
          reference.ordre = index;

          console.log(reference);
          console.table(reference.param());

          await this.referenceRepository.save(reference.id, reference);
        }
      }

      this.modalBody = changement
        ? "L'enregistrement a été effectué avec succès."
        : 'Aucun changement a effectuer.';

      // this.modalConfirm = true;
      this.find();
    } finally {
      this.saving = false;
    }
  }

  confirm() {
    this.$router.push({ name: 'admin_reference_index' });
  }

}
