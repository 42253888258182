<template>
  <Loading class="mt-5" v-if="loading" />
  <Form :form="form" v-else />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Form from '@/components/form/Reference.vue';
import Loading from '@/components/Loading.vue';

import { ReferenceForm } from '@/form/ReferenceForm';
import { ReferenceApiDto, ReferenceRepository } from '@/repository/ReferenceRepository';
import { Reference } from '@/model/Reference';
import { ServiceManager } from '@/service/ServiceManager';

@Options({
  props: {},
  components: {
    Form,
    Loading
  }
})
export default class Edit extends Vue {

  private readonly referenceRepository = ServiceManager.get(ReferenceRepository.factoryName) as ReferenceRepository;

  form: ReferenceForm = new ReferenceForm();
  loading = true;

  get id(): number {
    return +this.$route.params.id;
  }

  created() {
    const dto: ReferenceApiDto = new ReferenceApiDto();

    dto.with.push('images.image');
    dto.with.push('legendes.legende');

    this.referenceRepository.find(this.id, dto)
      .then((reference: Reference) => {
        this.form.id = reference.id;
        this.form.titre = reference.titre;
        this.form.localisation = reference.localisation;
        this.form.etat = reference.etat;
        this.form.description = reference.description;
        this.form.legendes = reference.legendes;
        this.form.images = reference.images;
        this.form.actif = reference.actif;
      })
      .finally(() => { this.loading = false; });
  }

}
</script>

<style scoped lang="scss">
</style>
