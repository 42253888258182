<template>
  <div class="row">
    <div class="col-12 col-sm-8 col-md-9">
      <Presentation />
      <br />

      <div class="row">
        <div class="col-12 d-sm-none d-xs-block">
          <Domaine />
          <br />
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <Demarche />
        </div>
      </div>
    </div>

    <div class="col-12 col-sm-4 col-md-3 d-none d-sm-block">
      <Domaine />
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Presentation from '@/components/Presentation.vue';
import Domaine from '@/components/Domaine.vue';
import Demarche from '@/components/Demarche.vue';

@Options({
  components: {
    Presentation,
    Domaine,
    Demarche
  }
})
export default class Agence extends Vue {

}
</script>

<style scoped lang="scss">
div {
  font-size: 14px;
}

.col-12.col-sm-8.col-md-9,
.col-12.col-sm-4.col-md-3 {
  margin-bottom: 20px;
}
</style>
