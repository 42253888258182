import { ServiceManager } from '@/service/ServiceManager';
import { StorageService } from '@/service/StorageService';
import { AuthService } from '@/service/AuthService';
import { StoreService } from '@/service/StoreService';
import { AuthRepository } from '@/repository/AuthRepository';
import { UserRepository } from '@/repository/UserRepository';

export function AuthServiceFactory(): AuthService {
  return new AuthService(
    ServiceManager.get(StorageService.user) as StorageService,
    ServiceManager.get(StoreService.factoryName) as StoreService,
    ServiceManager.get(AuthRepository.factoryName) as AuthRepository,
    ServiceManager.get(UserRepository.factoryName) as UserRepository
  );
}
