import { AbstractBuilder } from '@/model/builder/_Builder';
import { Image, ImageCollection } from '@/model/Image';
import { ImageDtoInterface } from '@/interface/dto/ImageDtoInterface';
import { CollectionDtoInterface } from '@/interface/dto/CollectionDtoInterface';
import { ReferenceImagesBuilder } from '@/model/builder/ReferenceImagesBuilder';
import { ReferenceImagesDtoInterface } from '@/interface/dto/ReferenceImagesDtoInterface';
import { ReferenceImage } from '@/model/ReferenceImage';
import { Reference } from '@/model/Reference';

export class ImageBuilder extends AbstractBuilder {

  static build(data: ImageDtoInterface): Image {
    const model: Image = new Image();

    if (typeof data.id === 'number') {
      model.id = data.id;
    }

    if (typeof data.source === 'string') {
      model.source = data.source;
    }

    if (typeof data.projets === 'object') {
      ReferenceImagesBuilder
        .buildCollection(data.projets as ReferenceImagesDtoInterface[])
        .forEach((referenceImage: ReferenceImage) => {
          model.references.push(referenceImage.reference as Reference);
        });
    }

    return model;
  }

  static buildCollection(data: CollectionDtoInterface): ImageCollection {
    const collection: ImageCollection = new ImageCollection();
    const result = data.result as ImageDtoInterface[];

    Object.values(result).forEach((element: ImageDtoInterface) => {
      collection.images.push(this.build(element));
    });

    if (typeof data.total === 'number') {
      collection.total = data.total;
    }

    return collection;
  }

}
