<template>
  <ModalConfirm v-if="modalConfirm" v-on:confirm="confirm"
                buttonKo=""
                buttonOkClass="btn-secondary"
                buttonOk="retour aux références"
                body="L'enregistrement a été effectué avec succès."
                header="<i class='fas fa-check-circle text-success'></i> <b>Enregistrement</b>" />

  <SelectionImage v-if="selectionImage" @fermer="selectionImage = false" :imagesSelected="form.images" :multiple="true" />

  <form @submit.prevent="submit" v-else>
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label>Affichage sur le site</label>
          <div class="custom-control custom-switch">
            <input type="checkbox" id="actif" class="custom-control-input" v-model="form.actif">
            <label for="actif" class="custom-control-label">{{ form.actif ? 'visible' : 'non visible' }}</label>
          </div>
        </div>
        <div class="form-group">
          <label for="titre">Titre</label>
          <input type="text" class="form-control " id="titre" placeholder="-"
                 v-model="form.titre" @blur="form.verifyTitre()"
                 :class="{ 'is-valid': form.error.titre === '', 'is-invalid': form.error.titre }"/>
          <small v-if="form.error.titre" class="text-danger">{{ form.error.titre }}</small>
        </div>
        <div class="form-group">
          <label for="localisation">Localisation</label>
          <input type="text" class="form-control" id="localisation" placeholder="-"
                 v-model="form.localisation" @blur="form.verifyLocalisation()"
                 :class="{ 'is-valid': form.error.localisation === '', 'is-invalid': form.error.localisation }" />
          <small v-if="form.error.localisation" class="text-danger">{{ form.error.localisation }}</small>
        </div>
        <div class="form-group">
          <label for="etat">Etat</label>
          <input type="text" class="form-control" id="etat" placeholder="-"
                 v-model="form.etat" @blur="form.verifyEtat()"
                 :class="{ 'is-valid': form.error.etat === '', 'is-invalid': form.error.etat }" />
          <small v-if="form.error.etat" class="text-danger">{{ form.error.etat }}</small>
        </div>
        <div class="form-group">
          <label>Description</label>
          <textarea rows="5" class="form-control" id="description" placeholder="-"
                    v-model="form.description" @blur="form.verifyDescription()"
                 :class="{ 'is-valid': form.error.description === '', 'is-invalid': form.error.description }" />
          <small v-if="form.error.description" class="text-danger">{{ form.error.description }}</small>
        </div>
        <div class="form-group" v-if="legendesRef">
          <div class="d-flex justify-content-between align-items-center">
            <label>Legendes</label>
          </div>
          <div class="row mb-1" v-for="(legende, index) in form.legendes" v-bind:key="index">
            <div class="pr-md-0 col-md-6 col-12">
              <select class="form-control" v-model="legende.legende" @change="form.verifyLegendesRef()"
                      :class="{ 'is-invalid': form.validLegendes[index] && form.validLegendes[index].ref === false }">
                <option v-for="legendeRef in legendesRefAvailable(index)" v-bind:key="legendeRef.id" :value="legendeRef">{{ legendeRef.label }}</option>
              </select>
            </div>
            <div class="col-md-6 col-10 d-flex align-items-center">
              <input type="text" class="form-control" id="legendes" placeholder="-" :disabled="form.legendes[index].legende === undefined"
                     v-model="legende.value" @blur="form.verifyLegende(index)"
                     :class="{
                     'is-valid': form.validLegendes[index] && form.validLegendes[index].value === true,
                     'is-invalid': form.validLegendes[index] && form.validLegendes[index].value === false
                   }" />
              <i class="far fa-times-circle text-danger pointer ml-2" @click="delLegende(index)" />
            </div>
            <div class="col-12 ml-4" v-if="form.validLegendes[index] && form.validLegendes[index].message.length > 0">
              <small class="text-danger" v-html="form.validLegendes[index].message.join('<br />')" />
            </div>
          </div>
          <div class="row mb-1">
            <div class="offset-md-6 col-md-6 col-10 text-right">
            <span class="badge badge-secondary pointer" @click="addLegende()">
              <i class="fa fa-plus" />
              ajouter une ligne
            </span>
            </div>
          </div>

          <p class="mt-4">
            <button class="btn btn-success mr-1" type="submit" :class="{ disabled: saving }">Enregistrer</button>
            <router-link v-if="!newRoute" :to="{ name: 'admin_reference_edit_list' }" class="btn btn-dark">Retour</router-link>
          </p>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <div class="d-flex justify-content-between align-items-center">
            <label>Images</label>
            <span class="badge badge-secondary pointer" @click="selectionImage = true">
              <i class="fas fa-plus" />
              choisir les photos
            </span>
          </div>
          <div class="d-flex justify-content-between flex-wrap">
            <draggable v-model="form.images" tag="transition-group" item-key="id">
              <template #item="{element}">
                <span class="img-draggable">
                  <img style="max-width: 175px;" class="m-1 img-fluid img-thumbnail sortable-col-click pointer"
                       :src="'/images/references/' + element.source" @click="togglePrev(element)" />
                </span>
              </template>
            </draggable>
          </div>
          <div class="info">
            Info :
            <ul>
              <li>Faire <span class="expo">glisser</span> les images pour modifier <span class="expo">l'ordre</span>.</li>
              <li>L'image principale (et la vignette) sera la première image.</li>
              <li><span class="expo">Cliquer</span> sur la vignette pour une visualisation en grand ci-dessous.</li>
            </ul>
          </div>
          <div v-if="prev" class="text-center mt-3">
            <img class="img-fluid img-thumbnail pointer" :src="'/images/references/' + prev.source" style="max-height: 500px;" @click="closePrev()" />
          </div>
        </div>
      </div>
    </div>
  </form>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Draggable from 'vuedraggable';
import SelectionImage from '@/components/image/Selection.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

import { ReferenceForm } from '@/form/ReferenceForm';
import { LegendeRef, LegendeRefCollection } from '@/model/LegendeRef';
import { LegendeRepository } from '@/repository/LegendeRepository';
import { Legende } from '@/model/Legende';
import { Image } from '@/model/Image';

import { ReferenceRepository } from '@/repository/ReferenceRepository';
import { ServiceManager } from '@/service/ServiceManager';

@Options({
  props: {
    form: ReferenceForm
  },
  components: {
    Draggable,
    SelectionImage,
    ModalConfirm
  }
})
export default class Reference extends Vue {

  private readonly legendeRepository = ServiceManager.get(LegendeRepository.factoryName) as LegendeRepository;
  private readonly referenceRepository = ServiceManager.get(ReferenceRepository.factoryName) as ReferenceRepository;

  form!: ReferenceForm;
  legendesRef: LegendeRef[] = [];
  prev: Image | null = null;
  selectionImage = false;
  saving = false;
  editLegendes = false;

  modalConfirm = false;
  newRoute = false;

  created() {
    this.newRoute = this.$router.currentRoute.value.name === 'admin_reference_new';

    if (this.form.legendes.length === 0) {
      this.addLegende();
    }

    this.legendeRepository.ref()
      .then((collection: LegendeRefCollection) => {
        this.legendesRef = collection.legendes;
      });
  }

  delLegende(index: number) {
    this.form.legendes.splice(index, 1);
  }

  addLegende() {
    this.form.legendes.push(new Legende());
  }

  submit() {
    if (!this.saving) {
      if (this.form.isValid()) {
        this.saving = true;

        this.referenceRepository.save(this.form.id, this.form)
          .then(() => {
            this.modalConfirm = true;
          })
          .catch(() => {
            alert('Une erreur s\'est produite, veuillez réessayer ultérieurement');
          })
          .finally(() => {
            this.saving = false;
          });
      } else {
        alert('Une erreur s\'est produite, veuillez réessayer ultérieurement');
      }
    }
  }

  togglePrev(image: Image) {
    if (this.prev === image) {
      this.closePrev();
    } else {
      this.prev = image;
    }
  }

  closePrev() {
    this.prev = null;
  }

  legendesRefAvailable(index: number) {
    return this.legendesRef.filter((legendeRef: LegendeRef) => {
      return !this.form.legendes.some((legende: Legende, i: number) => {
        return index !== i && legende.legende && legende.legende.id === legendeRef.id;
      });
    });
  }

  confirm() {
    this.$router.push({ name: 'admin_reference_list' });
  }

}
</script>

<style scoped lang="scss">
@import 'src/assets/scss/variables';

.info {
  font-style: italic;
  font-size: 0.9em;
}

.img-draggable:first-of-type > img {
  border-color: $dark;
}

.expo {
  text-decoration: underline;
  font-weight: bold;
}
</style>
