<template>
  <router-view />
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Reference extends Vue {

}
</script>

<style scoped lang="scss">
</style>
