import { StorageService } from '@/service/StorageService';
import { StoreService } from '@/service/StoreService';
import { AuthService } from '@/service/AuthService';

import { AuthServiceFactory } from '@/service/factory/AuthServiceFactory';
import { UserStorageServiceFactory } from '@/service/factory/StorageServiceFactory';
import { StoreServiceFactory } from '@/service/factory/StoreServiceFactory';
import { AuthRepository } from '@/repository/AuthRepository';
import { AuthRepositoryFactory } from '@/repository/factory/AuthRepositoryFactory';
import { ApiService } from '@/service/ApiService';
import { ApiServiceFactory } from '@/service/factory/ApiServiceFactory';
import { ImageRepositoryFactory } from '@/repository/factory/ImageRepositoryFactory';
import { ImageRepository } from '@/repository/ImageRepository';
import { LegendeRepository } from '@/repository/LegendeRepository';
import { LegendeRepositoryFactory } from '@/repository/factory/LegendeRepositoryFactory';
import { ReferenceRepository } from '@/repository/ReferenceRepository';
import { ReferenceRepositoryFactory } from '@/repository/factory/ReferenceRepositoryFactory';
import { UserRepository } from '@/repository/UserRepository';
import { UserRepositoryFactory } from '@/repository/factory/UserRepositoryFactory';

export class ServiceManagerConfiguration {

  public static readonly conf: ConfFactoryInterface[] = [
    // Repository
    { name: AuthRepository.factoryName, factory: AuthRepositoryFactory },
    { name: ImageRepository.factoryName, factory: ImageRepositoryFactory },
    { name: LegendeRepository.factoryName, factory: LegendeRepositoryFactory },
    { name: ReferenceRepository.factoryName, factory: ReferenceRepositoryFactory },
    { name: UserRepository.factoryName, factory: UserRepositoryFactory },

    // Service
    { name: ApiService.factoryName, factory: ApiServiceFactory },
    { name: AuthService.factoryName, factory: AuthServiceFactory },
    { name: StoreService.factoryName, factory: StoreServiceFactory },
    { name: StorageService.user, factory: UserStorageServiceFactory }
  ];

}

export interface ConfFactoryInterface {
  name: string;
  factory: {};
  instance?: {};
}
