
import { Options, Vue } from 'vue-class-component';

import Presentation from '@/components/Presentation.vue';
import Domaine from '@/components/Domaine.vue';
import Demarche from '@/components/Demarche.vue';

@Options({
  components: {
    Presentation,
    Domaine,
    Demarche
  }
})
export default class Agence extends Vue {

}
