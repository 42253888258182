import Front from '@/views/Front.vue';

import Agence from '@/views/front/Agence.vue';
import Reference from '@/views/front/Reference.vue';
import ReferenceList from '@/views/front/reference/List.vue';
import ReferenceShow from '@/views/front/reference/Show.vue';
import Contact from '@/views/front/Contact.vue';
import Notfound from '@/views/front/Notfound.vue';

export default
{
  name: '_front',
  path: '/',
  component: Front,
  children: [
    {
      name: 'home',
      path: '',
      redirect: { name: 'reference_list' }
    },
    {
      name: 'agence',
      path: '/agence',
      component: Agence
    },
    {
      name: 'old_reference',
      path: '/references',
      redirect: { name: 'reference_list' }
    },
    {
      name: 'reference',
      path: '/reference',
      component: Reference,
      children: [
        {
          path: '',
          name: 'reference_list',
          component: ReferenceList
        },
        {
          path: ':id',
          name: 'reference_show',
          component: ReferenceShow
        }
      ]
    },
    {
      name: 'contact',
      path: '/contact',
      component: Contact
    },
    {
      name: '404',
      path: '/:pathMatch(.*)*',
      component: Notfound
    }
  ]
};
