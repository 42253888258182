import { AbstractBuilder } from '@/model/builder/_Builder';
import { ReferenceImagesDtoInterface } from '@/interface/dto/ReferenceImagesDtoInterface';
import { ReferenceImage } from '@/model/ReferenceImage';
import { ImageBuilder } from '@/model/builder/ImageBuilder';
import { ImageDtoInterface } from '@/interface/dto/ImageDtoInterface';
import { ReferenceDtoInterface } from '@/interface/dto/ReferenceDtoInterface';
import { ReferenceBuilder } from '@/model/builder/ReferenceBuilder';

export class ReferenceImagesBuilder extends AbstractBuilder {

  static build(data: ReferenceImagesDtoInterface): ReferenceImage {
    const model: ReferenceImage = new ReferenceImage();

    if (typeof data.projet_id === 'number') {
      model.referenceId = data.projet_id;
    }

    if (typeof data.image_id === 'number') {
      model.imageId = data.image_id;
    }

    if (typeof data.ordre === 'number') {
      model.ordre = data.ordre;
    }

    if (typeof data.projet === 'object') {
      model.reference = ReferenceBuilder.build(data.projet as ReferenceDtoInterface);
    }

    if (typeof data.image === 'object') {
      model.image = ImageBuilder.build(data.image as ImageDtoInterface);
    }

    return model;
  }

  static buildCollection(data: ReferenceImagesDtoInterface[]): ReferenceImage[] {
    const collection: ReferenceImage[] = [];

    Object.values(data).forEach((element: ReferenceImagesDtoInterface) => {
      collection.push(this.build(element));
    });

    return collection;
  }

}
