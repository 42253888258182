import { ApiParamInterface } from '@/interface/service/ApiParamInterface';
import { LegendeRef } from '@/model/LegendeRef';
import { FormInterface } from '@/interface/form/FormInterface';

export class LegendeForm implements FormInterface, ApiParamInterface {

  legende: LegendeRef;
  value: string | undefined;

  constructor(legende: LegendeRef) {
    this.legende = legende;
    this.value = legende.label;
  }

  param(): {} {
    return {
      label: this.legende.label
    };
  }

  isValid(): boolean {
    return true;
  }

}
