<template>
  <Caroussel v-if="reference.images.length > 0 && caroussel.show" @close="closeCaroussel()" :images="reference.images" :current="caroussel.current" />

  <div class="row">
    <div class="col-12">
      <h2>{{ reference.titre }}</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-12 col-md-6">
      <div class="row">
        <div class="d-none d-sm-inline-block col-md-6 cartouche" v-html="reference.description"></div>

        <div class="col-md-6 detail">
          <span :key="key" v-for="(legende, key) in legendes">
          <b>{{ legende.label }} :</b> {{ legende.value }}<br/>
          </span>
        </div>
      </div>
    </div>
    <div class="col-12 col-md-6">
      <img class="img-fluid"
           v-if="reference.cartouche" @click="openCaroussel(reference.cartouche.id)"
           :src="'/images/references/' + reference.cartouche.source" />
    </div>
  </div>

  <div class="row mt-5 slide" v-if="reference.slider.length > 0">
    <div :key="key" class="col-3" style="text-align: center; padding: 5px;" v-for="(image, key) in reference.slider">
      <img :src="'/images/references/' + image.source" class="img-fluid" @click="openCaroussel(image.id)" />
    </div>
  </div>

  <div class="row mt-5">
    <div class="col-12 text-right">
      <router-link :to="{ name: 'reference_list' }" class="btn btn-dark">
        retour aux références
      </router-link>
    </div>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Legende } from '@/model/Legende';
import { Reference } from '@/model/Reference';

import Caroussel from '@/components/Caroussel.vue';

@Options({
  props: {
    reference: Reference
  },
  components: {
    Caroussel
  }
})
export default class Complete extends Vue {

  reference!: Reference;
  caroussel = {
    show: false,
    current: 0
  };

  get id(): number {
    return +this.$route.params.id;
  }

  get legendes(): {label: string; value: string}[] {
    const legendes: {label: string; value: string}[] = [];

    this.reference.legendes.forEach((legende: Legende) => {
      if (legende.label && legende.value) {
        legendes.push({
          label: legende.label,
          value: legende.value
        });
      }
    });

    if (this.reference.localisation) {
      legendes.push({
        label: 'Localisation',
        value: this.reference.localisation
      });
    }

    if (this.reference.etat) {
      legendes.push({
        label: 'Etat',
        value: this.reference.etat
      });
    }

    return legendes;
  }

  openCaroussel(id: number) {
    this.caroussel.show = true;
    this.caroussel.current = id;
  }

  closeCaroussel() {
    this.caroussel.show = false;
    this.caroussel.current = 0;
  }

}
</script>

<style scoped lang="scss">
img:hover {
  cursor: pointer;
}

.cartouche {
  font-size: 0.9em;
  text-align: justify;
}

.detail {
  font-style: italic;
  text-align: right;
  font-size: 0.8em;
}

h2 {
  text-transform: uppercase;
}
</style>
