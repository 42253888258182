<template>
  <ModalConfirm v-if="modalConfirm.show" v-on:confirm="confirm"
                :header="modalConfirm.header" :body="modalConfirm.body" :param="modalConfirm.param" />

  <table class="table table-striped table-hover border-bottom">
    <thead class="thead-dark">
    <tr >
      <th>
        Légende
      </th>
      <th />
    </tr>
    </thead>
    <tbody v-if="loading">
    <Loading :colspan="3" />
    </tbody>
    <tbody v-else>
    <template v-for="(form, key) in legendesForm" :key="key">
      <tr v-if="editLegende === form.legende.id">
        <td>
          <input type="text" v-model="form.value" class="form-control" />
        </td>
        <td class="text-right">
          <button class="btn btn-sm btn-success mr-1" @click="save(form)">enregistrer</button>
          <button class="btn btn-sm btn-secondary" @click="cancel(form)">annuler</button>
        </td>
      </tr>
      <tr v-else>
        <td>
          <span class="badge badge-secondary mr-1">{{ form.legende.nbProjet }}</span>
          {{ form.legende.label }}
        </td>
        <td class="text-right">
          <button class="btn btn-sm btn-secondary mr-1" v-if="editLegende === -1" @click="editLegende = form.legende.id">modifier</button>
          <button class="btn btn-sm btn-danger" v-if="editLegende === -1" @click="del(form.legende)">supprimer</button>
        </td>
      </tr>
    </template>
    <template v-if="!loading">
      <tr v-if="editLegende === newLegende.legende.id">
        <td>
          <input type="text" v-model="newLegende.value" class="form-control" />
        </td>
        <td class="text-right">
          <button class="btn btn-sm btn-success mr-1" @click="save(newLegende)">enregistrer</button>
          <button class="btn btn-sm btn-secondary" @click="cancel(newLegende)">annuler</button>
        </td>
      </tr>
      <tr v-else>
        <td/>
        <td class="text-right">
          <button class="btn btn-sm btn-primary mr-1" v-if="editLegende === -1" @click="editLegende = newLegende.legende.id">ajouter</button>
        </td>
      </tr>
    </template>
    </tbody>
  </table>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

import Loading from '@/components/table/Loading.vue';
import ModalConfirm from '@/components/ModalConfirm.vue';

import { ServiceManager } from '@/service/ServiceManager';
import { LegendeRef } from '@/model/LegendeRef';
import { LegendeRefApiDto, LegendeRepository } from '@/repository/LegendeRepository';
import { LegendeForm } from '@/form/LegendeForm';

@Options({
  components: {
    Loading,
    ModalConfirm
  }
})
export default class Legende extends Vue {

  private readonly legendeRepository = ServiceManager.get(LegendeRepository.factoryName) as LegendeRepository;

  legendes: LegendeRef[] = [];
  legendesForm: LegendeForm[] = [];
  newLegende: LegendeForm = new LegendeForm(new LegendeRef());
  loading = false;
  saving = false;
  editLegende = -1;

  modalConfirm: { header: string; body: string; param: LegendeRef | null; show: boolean } = {
    header: '<i class="fas fa-exclamation-circle"></i> <b>Attention</b>',
    body: '',
    param: null,
    show: false
  }

  created() {
    this.find();
  }

  private init() {
    this.newLegende = new LegendeForm(new LegendeRef());
    this.legendesForm = [];
    this.saving = false;
    this.editLegende = -1;
  }

  private async find() {
    this.init();

    const dto = new LegendeRefApiDto();

    dto.orderBy = 'label';
    dto.withCount.push('projets');

    this.loading = true;

    const legendeCollection = await this.legendeRepository.ref(dto);

    for (const legende of legendeCollection.legendes) {
      this.legendesForm.push(new LegendeForm(legende));
    }

    this.legendes = legendeCollection.legendes;

    this.loading = false;
  }

  public cancel(form: LegendeForm) {
    form.value = form.legende.label;

    this.editLegende = -1;
  }

  public async save(form: LegendeForm) {
    if (!this.saving) {
      if (form.isValid()) {
        form.legende.label = form.value;

        this.saving = true;

        try {
          await this.legendeRepository.save(form.legende.id, form);
          await this.find();
        } catch (err) {
          alert('Une erreur s\'est produite, veuillez réessayer ultérieurement');
        }
      }
    }
  }

  del(legende: LegendeRef) {
    this.modalConfirm.body = 'Etes vous sur de vouloir supprimer <u><b>' + legende.label + '</b></u> ?';
    this.modalConfirm.param = legende;
    this.modalConfirm.show = true;
  }

  public async confirm(response: boolean, legende: LegendeRef) {
    if (response && legende.id) {
      try {
        await this.legendeRepository.delete(legende.id);
        this.find();
      } catch (err) {
        alert('Une erreur s\'est produite, veuillez réessayer ultérieurement');
      }
    }

    this.modalConfirm.show = false;
  }

}
</script>

<style scoped lang="scss">
table {
  max-width: 750px;
}
</style>
