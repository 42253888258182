
import { Options, Vue } from 'vue-class-component';
import { Reference } from '@/model/Reference';
import { Image } from '@/model/Image';

@Options({
  props: {
    reference: Reference
  }
})
export default class Miniature extends Vue {

  reference!: Reference;
  hover = false;

  get styleCartouche() {
    let style = '';

    if (this.reference.cartouche instanceof Image) {
      style = 'background-image: url("/images/references/' + this.reference.cartouche.source + '");';
    }

    return style;
  }

}
