<template>
  <tr>
    <td class="text-center bg-white" :colspan="colspan">
      <img src="@/assets/img/loading.gif" class="img-fluid" />
    </td>
  </tr>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    colspan: Number
  }
})
export default class Loading extends Vue {

  colspan = 1;

}
</script>

<style scoped lang="scss">
</style>
