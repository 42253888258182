import { reactive } from 'vue';
import { createStore } from 'vuex';

import agenceStore from '@/store/agence';
import userStore from '@/store/user';

import referencesModule from '@/store/references';

const store = createStore({
  state: reactive({
    agence: agenceStore,
    user: userStore,
    minified: false,
    authenticated: true
  }),
  mutations: {
    toggleMinified: (state) => {
      state.minified = !state.minified;
    }
  },
  modules: {
    reference: referencesModule
  }
});

export default store;

export interface Context {
  commit(arg0: string, arg1: unknown): void;
}
