
import { Options, Vue } from 'vue-class-component';
import { Legende } from '@/model/Legende';
import { Reference } from '@/model/Reference';

import Caroussel from '@/components/Caroussel.vue';

@Options({
  props: {
    reference: Reference
  },
  components: {
    Caroussel
  }
})
export default class Complete extends Vue {

  reference!: Reference;
  caroussel = {
    show: false,
    current: 0
  };

  get id(): number {
    return +this.$route.params.id;
  }

  get legendes(): {label: string; value: string}[] {
    const legendes: {label: string; value: string}[] = [];

    this.reference.legendes.forEach((legende: Legende) => {
      if (legende.label && legende.value) {
        legendes.push({
          label: legende.label,
          value: legende.value
        });
      }
    });

    if (this.reference.localisation) {
      legendes.push({
        label: 'Localisation',
        value: this.reference.localisation
      });
    }

    if (this.reference.etat) {
      legendes.push({
        label: 'Etat',
        value: this.reference.etat
      });
    }

    return legendes;
  }

  openCaroussel(id: number) {
    this.caroussel.show = true;
    this.caroussel.current = id;
  }

  closeCaroussel() {
    this.caroussel.show = false;
    this.caroussel.current = 0;
  }

}
