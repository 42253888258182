
import { Options, Vue } from 'vue-class-component';

@Options({
  props: {
    title: String
  }
})
export default class Title extends Vue {

  title = 'Title';

}
