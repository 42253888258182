<template>
  <div class="text-center">
    <img src="@/assets/img/loading.gif" class="img-fluid" />
    <span class="mt-1">chargement</span>
  </div>
</template>

<script lang="ts">
import { Vue } from 'vue-class-component';

export default class Loading extends Vue { }
</script>

<style scoped lang="scss">
span {
  display: block;
  font-style: italic;
  font-size: 0.8em;
}
</style>
