import { User } from '@/model/User';
import { ServiceManager } from '@/service/ServiceManager';
import { StorageService } from '@/service/StorageService';
import { UserBuilder } from '@/model/builder/UserBuilder';

const initDate = Date.now();

const userStorageService = ServiceManager.get(StorageService.user) as StorageService;
const data = userStorageService.getItem() as dataSession;

export default {
  xsrf: typeof data.xsrf === 'string' ? data.xsrf : '',
  user: typeof data.user === 'object' ? UserBuilder.build(data.user) : new User(),
  time: typeof data.time === 'number' ? data.time : 0,
  extend: function(xsrf?: string) {
    if (xsrf !== undefined) {
      this.xsrf = xsrf;
    }

    this.time = Date.now() + (15 * 60 * 1000); // +15 min;
  },
  signout: function() {
    this.xsrf = '';
    this.user = new User();
    this.time = 0;
  },
  authenticated: function() {
    return this.xsrf !== '' && typeof this.user.id !== 'undefined';
  },
  old: function() {
    return this.authenticated() && this.time < initDate;
  },
  toSave: function(): dataSession {
    return {
      xsrf: this.xsrf,
      user: this.user,
      time: this.time
    };
  }
};

type dataSession = {
  xsrf?: string;
  user?: User;
  time?: number;
}
