import { ApiService } from '@/service/ApiService';

export abstract class AbstractRepository {

  private readonly _apiService: ApiService;

  constructor(apiService: ApiService) {
    this._apiService = apiService;
  }

  get apiService(): ApiService {
    return this._apiService;
  }

}
