import { ServiceManagerConfiguration } from '@/service/ServiceManagerConfiguration';

export class ServiceManager {

  private static _findConf(name: string) {
    return ServiceManagerConfiguration.conf.find((conf: ConfFactoryInterface) => {
      return conf.name === name;
    });
  }

  private static _generateInstance(conf: ConfFactoryInterface) {
    let instance = null;

    if (typeof conf.factory === 'function') {
      instance = conf.factory.call({});
    }

    return instance;
  }

  static get(name: string) {
    const conf: ConfFactoryInterface | undefined = ServiceManager._findConf(name);

    if (typeof conf === 'undefined') {
      throw new Error();
    }

    if (typeof conf.instance === 'undefined') {
      const instance = ServiceManager._generateInstance(conf);

      if (typeof instance === 'object') {
        conf.instance = instance;
      }

    }

    if (typeof conf.instance !== 'object') {
      throw new Error();
    }

    return conf.instance;
  }

}

interface ConfFactoryInterface {
  name: string;
  factory: {};
  instance?: {};
}
