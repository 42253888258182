import { AbstractApiParam } from '@/service/ApiService';
import { User } from '@/model/User';
import { UserDtoInterface } from '@/interface/dto/UserDtoInterface';
import { UserBuilder } from '@/model/builder/UserBuilder';
import { AbstractRepository } from '@/repository/AbstractRepository';

export class UserRepository extends AbstractRepository {

  public static readonly factoryName = 'UserRepository';

  public async me(): Promise<User> {
    const data = await this.apiService.get('user/me') as UserDtoInterface;

    return UserBuilder.build(data);
  }

}

export class UserApiDto extends AbstractApiParam {}
