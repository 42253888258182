import { AbstractModel, AbstractModelCollection } from '@/model/_Model';
import { Legende } from '@/model/Legende';
import { Image } from '@/model/Image';
import { ReferenceInterface } from '@/interface/model/ReferenceInterface';
import { ReferenceImage } from '@/model/ReferenceImage';
import { ApiParamInterface } from '@/interface/service/ApiParamInterface';

export class Reference extends AbstractModel implements ReferenceInterface, ApiParamInterface {

  private _id?: number;
  private _ordre?: number;
  private _actif?: boolean;
  private _titre?: string;
  private _localisation?: string;
  private _etat?: string;
  private _description?: string;
  private _legendes: Legende[] = [];
  private _images: Image[] = [];
  private _slider: Image[] = [];
  private _cartouche?: Image;

  get id(): number | undefined {
    return this._id;
  }

  set id(value: number | undefined) {
    this._id = value;
  }

  get ordre(): number | undefined {
    return this._ordre;
  }

  set ordre(value: number | undefined) {
    this._ordre = value;
  }

  get actif(): boolean | undefined {
    return this._actif;
  }

  set actif(value: boolean | undefined) {
    this._actif = value;
  }

  get titre(): string | undefined {
    return this._titre;
  }

  set titre(value: string | undefined) {
    this._titre = value;
  }

  get localisation(): string | undefined {
    return this._localisation;
  }

  set localisation(value: string | undefined) {
    this._localisation = value;
  }

  get etat(): string | undefined {
    return this._etat;
  }

  set etat(value: string | undefined) {
    this._etat = value;
  }

  get description(): string | undefined {
    return this._description;
  }

  set description(value: string | undefined) {
    this._description = value;
  }

  get legendes(): Legende[] {
    return this._legendes;
  }

  set legendes(value: Legende[]) {
    this._legendes = value;
  }

  get images(): Image[] {
    return this._images;
  }

  set images(value: Image[]) {
    this._images = value;
  }

  get cartouche(): Image | undefined {
    return this._cartouche;
  }

  get slider(): Image[] {
    return this._slider;
  }

  setImages(referenceImage: ReferenceImage[]) {
    this._images = [];
    this._slider = [];
    this._cartouche = undefined;

    referenceImage.forEach((referenceImage: ReferenceImage) => {
      if (referenceImage.image) {
        this._images.push(referenceImage.image);

        if (this._cartouche) {
          this._slider.push(referenceImage.image);
        } else {
          this._cartouche = referenceImage.image;
        }
      }
    });
  }

  param(): {} {
    const legendes: {id: number; ordre: number; value: string}[] = [];
    const images: {id: number; ordre: number}[] = [];

    this.legendes.forEach((legende: Legende, index: number) => {
      if (legende.legende !== undefined && legende.legende.id !== undefined && legende.value !== undefined) {
        legendes.push({
          id: legende.legende.id,
          value: legende.value,
          ordre: index
        });
      }
    });

    this.images.forEach((image: Image, index: number) => {
      if (image.id !== undefined) {
        images.push({
          id: image.id,
          ordre: index
        });
      }
    });

    return {
      titre: this.titre ?? null,
      localisation: this.localisation ?? null,
      etat: this.etat ?? null,
      description: this.description ?? null,
      ordre: this.ordre ?? null,
      legendes: legendes,
      images: images
    };
  }

}

export class ReferenceCollection extends AbstractModelCollection {

  private _references: Reference[] = [];

  get references(): Reference[] {
    return this._references;
  }

  set references(value: Reference[]) {
    this._references = value;
  }

}
