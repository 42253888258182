
import { Options, Vue } from 'vue-class-component';

@Options({
  emits: ['confirm'],
  props: {
    header: {
      type: String,
      default: ''
    },
    body: {
      type: String,
      default: 'Etes vous sur ?'
    },
    buttonOk: {
      type: String,
      default: 'Oui'
    },
    buttonKo: {
      type: String,
      default: 'Non'
    },
    buttonOkClass: {
      type: String,
      default: 'btn-success'
    },
    buttonKoClass: {
      type: String,
      default: 'btn-danger'
    },
    param: Object
  }
})
export default class ModalConfirm extends Vue {

  header!: string;
  body!: string;
  buttonOk!: string;
  buttonKo!: string;
  param?: unknown;
  buttonOkClass!: string;
  buttonKoClass!: string;

}
