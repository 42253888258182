
import { Options, Vue } from 'vue-class-component';

import Title from '@/components/Title.vue';
import ComingSoon from '@/components/ComingSoon.vue';

@Options({
  components: {
    Title,
    ComingSoon
  }
})
export default class Photo extends Vue {

  soon = true;

}
