import { AbstractBuilder } from '@/model/builder/_Builder';
import { User, UserType } from '@/model/User';

export class UserBuilder extends AbstractBuilder {

  static build(data: UserType): User {
    const model: User = new User();

    if (typeof data.id === 'number') {
      model.id = data.id;
    }

    if (typeof data.name === 'string') {
      model.name = data.name;
    }

    if (typeof data.email === 'string') {
      model.email = data.email;
    }

    return model;
  }

}
