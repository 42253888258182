import { AbstractApiParam } from '@/service/ApiService';
import { ApiParamInterface } from '@/interface/service/ApiParamInterface';
import { AbstractRepository } from '@/repository/AbstractRepository';

export class AuthRepository extends AbstractRepository {

  public static readonly factoryName = 'AuthRepository';

  public async verify() {
    return await this.apiService.get('auth/verify');
  }

  public async auth(dto?: ApiParamInterface): Promise<string> {
    const data = await this.apiService.post('auth', dto) as { xsrf: string };

    return data.xsrf;
  }

  public async refresh(): Promise<string> {
    const data = await this.apiService.post('auth/refresh') as { xsrf: string };

    return data.xsrf;
  }

}

export class AuthApiDto extends AbstractApiParam {}
