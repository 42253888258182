
import { Options, Vue } from 'vue-class-component';

import { AuthForm } from '@/form/AuthForm';
import { AuthService } from '@/service/AuthService';
import { ServiceManager } from '@/service/ServiceManager';
import store from '@/store/_index';

@Options({})
export default class Auth extends Vue {

  private authService: AuthService = ServiceManager.get(AuthService.factoryName) as AuthService;

  check = false;

  form: AuthForm = new AuthForm();
  connexion = false;
  error = false;

  get userStore() {
    return store.state.user;
  }

  created() {
    this.verify();
  }

  public async submit() {
    if (!(await this.authService.authByForm(this.form))) {
      alert('Error');
    }
  }

  public async verify() {
    if (this.userStore.old()) {
      this.check = true;
      await this.authService.verify();
      this.check = false;
    }
  }

}
