import { ApiParamInterface } from '@/interface/service/ApiParamInterface';
import { ReferenceInterface } from '@/interface/model/ReferenceInterface';
import { Legende } from '@/model/Legende';
import { FormInterface } from '@/interface/form/FormInterface';
import { Image } from '@/model/Image';

export class ReferenceForm implements FormInterface, ApiParamInterface, ReferenceInterface {

  id?: number;
  actif?: boolean = true;
  titre?: string;
  localisation?: string;
  etat?: string;
  description?: string;
  legendes: Legende[] = [];
  images: Image[] = [];

  error: ErrorInterface = {
    titre: undefined,
    localisation: undefined,
    etat: undefined,
    description: undefined,
    legendes: undefined
  };

  validLegendes: { ref?: boolean; value?: boolean; message: string[] }[] = [];

  param(): {} {
    const legendes: {id: number; ordre: number; value: string}[] = [];
    const images: {id: number; ordre: number}[] = [];

    this.legendes.forEach((legende: Legende, index: number) => {
      if (legende.legende !== undefined && legende.legende.id !== undefined && legende.value !== undefined) {
        legendes.push({
          id: legende.legende.id,
          value: legende.value,
          ordre: index
        });
      }
    });

    this.images.forEach((image: Image, index: number) => {
      if (image.id !== undefined) {
        images.push({
          id: image.id,
          ordre: index
        });
      }
    });

    return {
      titre: this.titre ? this.titre : null,
      localisation: this.localisation ? this.localisation : null,
      actif: this.actif === true,
      etat: this.etat ? this.etat : null,
      description: this.description ? this.description : null,
      legendes: legendes,
      images: images
    };
  }

  verifyTitre() {
    let message = '';

    if (this.titre === undefined || this.titre.length === 0) {
      message = 'Le titre est obligatoire.';
    } else if (this.titre.length > 255) {
      message = 'Le titre ne doit pas dépasser 255 caractères';
    }

    this.error.titre = message;
  }

  verifyLocalisation() {
    let message = '';

    if (this.localisation === undefined || this.localisation.length === 0) {
      message = 'La localisation est obligatoire.';
    } else if (this.localisation.length > 255) {
      message = 'La localisation ne doit pas dépasser 255 caractères';
    }

    this.error.localisation = message;
  }

  verifyEtat() {
    let message = '';

    if (this.etat === undefined || this.etat.length === 0) {
      message = 'L\'etat est obligatoire.';
    } else if (this.etat.length > 255) {
      message = 'L\'etat ne doit pas dépasser 255 caractères';
    }

    this.error.etat = message;
  }

  verifyDescription() {
    let message = '';

    if (this.description !== undefined && this.description.length > 60000) {
      message = 'La description ne doit pas dépasser 60 000 caractères';
    }

    this.error.description = message;
  }

  verifyLegendes() {
    this.error.legendes = this.legendes.every((legende: Legende, index: number) => {
      const l = this.verifyLegende(index);
      const r = this.verifyLegendeRef(legende, index);

      return l && r;
    });
  }

  verifyLegende(index: number): boolean {
    const legende = this.legendes[index];
    const valid: { ref?: boolean; value?: boolean; message: string[] } = this.validLegendes[index]
      ? this.validLegendes[index]
      : {
        ref: undefined,
        value: undefined,
        message: []
      };

    if (legende !== undefined) {
      valid.value = true;

      if (legende.legende === undefined && (legende.value === undefined || legende.value === '')) {
        valid.value = undefined;
        valid.message = [];
      } else {
        let errorMessage = 'La valeur de la légende est obligatoire.';

        if (legende.value === undefined || legende.value.length === 0) {
          valid.value = false;
          valid.message.push(errorMessage);
        } else {
          let i: number;

          i = valid.message.findIndex((message: string) => {
            return message === errorMessage;
          });

          if (i !== -1) {
            valid.message.splice(i, 1);
          }

          errorMessage = 'La valeur de la légende ne doit pas dépasser 255 caractères.';

          if (legende.value.length > 255) {
            valid.value = false;
            valid.message.push(errorMessage);
          } else {
            i = valid.message.findIndex((message: string) => {
              return message === errorMessage;
            });

            if (i !== -1) {
              valid.message.splice(i, 1);
            }
          }
        }
      }
    }

    this.validLegendes[index] = valid;

    return valid.value !== false;
  }

  verifyLegendesRef() {
    this.legendes.forEach((legende: Legende, index: number) => {
      this.verifyLegendeRef(legende, index);
    });
  }

  verifyLegendeRef(legende: Legende, index: number): boolean {
    const valid: { ref?: boolean; value?: boolean; message: string[] } = this.validLegendes[index]
      ? this.validLegendes[index]
      : {
        ref: undefined,
        value: undefined,
        message: []
      };

    const exist = this.legendes.some((l: Legende, i: number) => {
      return index !== i && l.legende !== undefined && legende.legende !== undefined && l.legende.id === legende.legende.id;
    });

    if (exist) {
      valid.ref = false;
      valid.message.push('La légende doit être unique.');
    } else {
      valid.ref = true;

      const i: number = valid.message.findIndex((message: string) => {
        return message === 'La légende doit être unique.';
      });

      if (i !== -1) {
        valid.message.splice(i, 1);
      }
    }

    this.validLegendes[index] = valid;

    return valid.ref;
  }

  isValid(): boolean {
    this.verifyTitre();
    this.verifyLocalisation();
    this.verifyEtat();
    this.verifyDescription();
    this.verifyLegendes();

    return (
      this.error.titre === '' &&
      this.error.localisation === '' &&
      this.error.etat === '' &&
      this.error.description === '' &&
      this.error.legendes === true
    );
  }

}

interface ErrorInterface {
  titre?: string;
  localisation?: string;
  etat?: string;
  description?: string;
  legendes?: boolean;
}
