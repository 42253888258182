import { FormInterface } from '@/interface/form/FormInterface';
import { ApiParamInterface } from '@/interface/service/ApiParamInterface';

export class AuthForm implements FormInterface, ApiParamInterface {

  email?: string = '';
  password?: string = '';

  verifyEmail(): boolean {
    return typeof this.email !== 'undefined' && this.email !== '';
  }

  verifyPassword(): boolean {
    return typeof this.password !== 'undefined' && this.password !== '';
  }

  isValid(): boolean {
    return this.verifyEmail() && this.verifyPassword();
  }

  param(): {} {
    return {
      email: this.email,
      password: this.password
    };
  }

}
